<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-10-25 14:22:12
 * @LastEditTime: 2022-11-08 10:12:23
 * @LastEditors: cyy
 * @Description: excel导入
 * @FilePath: \teacherdingding.com\src\views\LiveBroadcastManagement\messageTemplate\details\excelImport.vue
-->
<template>
  <div class="excelImport">
    <el-dialog
      title="Excel文件导入"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <div class="item mb10">
          <span class="tips">叮叮开课模拟对话模板.xls</span>

          <a href="https://f.dingdingkaike.com.cn/excelTemplate/TalkMsg.xlsx">
            <!-- 下载消息模板 -->
            <el-button type="text" class="ml40" @click.self.prevent>
              下载模板
            </el-button>
          </a>
        </div>

        <div class="item mb30">
          <span class="tips mr40">上传文件</span>
          <input
            ref="inputExcel"
            type="file"
            v-show="false"
            class="file"
            @change="fileChange"
            accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          <el-button
            type="primary"
            size="medium"
            class="mr30"
            @click="selectFile"
          >
            +选择
          </el-button>
          <span class="tips" v-if="file">已选择文件:{{ file.name }}</span>
        </div>

        <div class="item mt20">
          <span class="tips mr40">导入方式</span>
          <el-radio-group v-model="import_type">
            <el-radio :label="2">
              合并
              <helpIcon
                content="不清除原有记录，从最后一条记录开始插入"
              ></helpIcon>
            </el-radio>
            <el-radio :label="1">
              覆盖
              <helpIcon content="先清除原有记录，再插入新的记录"></helpIcon>
            </el-radio>
          </el-radio-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" @click="submit" size="medium">
          导 入
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    msg_id: {
      type: String,
      default: '',
    },

    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      file: '',
      import_type: 2,
    }
  },

  methods: {
    // 保存
    async submit() {
      if (!this.file) {
        this.$root.prompt('请先选择文件')
        return
      }
      let param = new FormData()
      param.append('file', this.file)
      param.append('import_type', this.import_type)
      param.append('msg_id', this.msg_id)
      var request = new XMLHttpRequest()

      request.open('post', `${location.origin}/chatMessage/excelRunImportTalk`)
      request.send(param)
      request.onload = () => {
        this.$root.prompt({
          msg: '操作成功',
          type: 'success',
        })
        this.$refs.inputExcel.value = ''
        this.$emit('fileChangeComplete')
        this.cancel()
      }
    },

    // 选择文件后提交
    fileChange(e) {
      const file = e.target.files[0]
      //   const maxSize = 10
      //   if (file.size > 1024 * 1024 * maxSize) {
      //     this.$root.prompt('上传文件大于10M')
      //     return
      //   }
      this.file = file
      e.target.value = ''
    },

    // 触发input
    selectFile() {
      this.$refs.inputExcel.click()
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.excelImport {
  ::v-deep .el-dialog__body {
    padding: 0 20px 20px;
  }
  .tips {
    font-size: 14px;
    color: #333333;
  }
}
</style>
