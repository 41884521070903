<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-10-24 16:49:38
 * @LastEditTime: 2022-11-07 11:09:37
 * @LastEditors: cyy
 * @Description: 模拟对话
 * @FilePath: \teacherdingding.com\src\views\LiveBroadcastManagement\messageTemplate\details\SimulateConversation.vue
-->
<template>
  <div class="simulateConversation">
    <div class="header">
      <div>
        <el-popover
          popper-class="simulateConversation_popper"
          placement="bottom"
          width="122"
          trigger="click"
        >
          <div class="popover_item">
            <div class="item" @click="addMessage">按发送人添加</div>
            <div class="item" @click="addbyCommentsStatus = true">
              按评论内容添加
            </div>
            <div class="item" @click="excelImportstatus = true">
              从Excel导入添加
            </div>
          </div>
          <el-button type="primary" slot="reference" size="medium">
            添加对话
            <i class="el-icon-arrow-down"></i>
          </el-button>
        </el-popover>
      </div>
      <div>
        <span class="tips mr10">关键词</span>
        <el-input
          clearable
          @keyup.enter.native="toSearch"
          style="width:310px"
          :placeholder="post.search_type == 2 ? '输入ID搜索' : '输入名称搜索'"
          v-model="search"
          class="input-with-select"
          size="medium"
        >
          <el-select
            style="width:111px"
            v-model="post.search_type"
            slot="prepend"
            placeholder="请选择"
          >
            <el-option label="用户名称" value="1"></el-option>
            <el-option label="用户ID" value="2"></el-option>
          </el-select>
        </el-input>
        <el-button type="primary" class="ml20" @click="toSearch" size="medium">
          搜 索
        </el-button>
      </div>
    </div>

    <!-- 表格 -->
    <pagination2
      ref="child"
      :option="post"
      url="/chatMessage/chatMsgDetails"
      @complete="complete"
      class="pagination2"
    >
      <template>
        <el-table
          ref="multipleTable"
          :data="completeData"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="45px"></el-table-column>
          <el-table-column label="用户ID" width="120px">
            <template slot-scope="{ row }">
              <div class=" robot_contain">
                <div class="nauser_idmes">{{ row.user_id }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="用户" min-width="120px">
            <template slot-scope="{ row }">
              <div class=" robot_contain">
                <div class="tu">
                  <img :src="row.uphoto" alt="" />
                </div>
                <div class="name  ">{{ row.uname }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="角色">
            <template slot-scope="{ row }">
              <div class=" robot_contain">
                <div class="name  ">{{ row.user_role | getRole }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="评论内容" min-width="250px">
            <template slot-scope="{ row }">
              <div
                class="flex-center"
                v-if="
                  changeType == 1 &&
                    changeVal.chat_msg_talk_id === row.chat_msg_talk_id
                "
              >
                <el-input
                  type="textarea"
                  rows="2"
                  v-model="row.content"
                  autofocus
                  autosize
                  show-word-limit
                  maxlength="200"
                ></el-input>
                <el-button type="text" class="ml10" @click="saveMessage(row)">
                  保存
                </el-button>
                <el-button
                  style="color: #999999"
                  type="text"
                  class="ml10"
                  @click="changeContent()"
                >
                  取消
                </el-button>
              </div>
              <div v-else class=" robot_contain" @click="changeContent(row)">
                <i class="el-icon-edit"></i>
                <div class="name  ">{{ row.content }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="停顿时长(秒)" min-width="220px">
            <div slot="header">
              停顿时长(秒)
              <helpIcon
                content="当前评论内容与下一条评论内容的发送间隔时长，比如：停顿时长设置5秒，当前评论发送后，等待5秒再发送下一条"
              ></helpIcon>
            </div>
            <template slot-scope="{ row }">
              <div
                class="flex-center"
                v-if="
                  changeType == 2 &&
                    changeVal.chat_msg_talk_id === row.chat_msg_talk_id
                "
              >
                <el-input-number
                  :min="1"
                  :max="60"
                  :step="1"
                  :key="row.chat_msg_talk_id"
                  size="small"
                  v-model="row.stop_time"
                ></el-input-number>
                <el-button type="text" class="ml10" @click="saveMessage(row)">
                  保存
                </el-button>
                <el-button
                  style="color: #999999"
                  type="text"
                  class="ml10"
                  @click="changeStopTime()"
                >
                  取消
                </el-button>
              </div>
              <div v-else class="robot_contain" @click="changeStopTime(row)">
                <i class="el-icon-edit"></i>
                <div class="name  ">{{ row.stop_time }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click="moveUpDown(scope.row.chat_msg_talk_id, 1)"
                :disabled="scope.$index == 0"
              >
                上移
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button
                size="mini"
                type="text"
                @click="moveUpDown(scope.row.chat_msg_talk_id, 2)"
                :disabled="scope.$index == completeData.length - 1"
              >
                下移
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <!-- <el-button
                size="mini"
                type="text"
                @click="editContent(scope.row)"
              >
                编辑
              </el-button> -->
              <!-- <el-divider direction="vertical"></el-divider> -->
              <el-button
                size="mini"
                type="text"
                @click="del(1, [scope.row.chat_msg_talk_id])"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <div class="footer">
          <el-checkbox
            v-model="overdue"
            :true-label="1"
            :false-label="2"
            class="ml10"
            :indeterminate="isIndeterminate"
            @change="checkALL"
          >
            <div class="tips ml10">已选择{{ selectList.length }}条</div>
          </el-checkbox>

          <el-button
            size="medium"
            style="width:130px"
            @click="
              del(
                2,
                selectList.map(item => item.chat_msg_talk_id)
              )
            "
            class=" ml20"
            :disabled="selectList.length == 0"
          >
            批量删除
          </el-button>
          <el-button
            size="medium"
            :disabled="selectList.length == 0"
            style="width:130px"
            @click="recoverContent"
          >
            批量修改内容
          </el-button>
          <el-button
            :disabled="selectList.length == 0"
            size="medium"
            style="width:130px"
            @click="recoverTime"
            class=" ml20"
          >
            批量修改时长
          </el-button>
        </div>
      </template>
    </pagination2>

    <!-- 添加对话 -->
    <add-conversation
      v-if="status"
      :dialogstatus.sync="status"
      :editList="editList"
      :msg_id="msg_id"
      @cancel="cancel"
      @addConversationComplete="addConversationComplete"
    ></add-conversation>

    <!-- excel导入 -->
    <excel-import
      v-if="excelImportstatus"
      :dialogstatus.sync="excelImportstatus"
      :msg_id="msg_id"
      @fileChangeComplete="listReset"
    ></excel-import>

    <!-- 按评论内容添加 -->
    <addby-comments
      v-if="addbyCommentsStatus"
      :msg_id="msg_id"
      :dialogstatus.sync="addbyCommentsStatus"
      @addbyCommentsComplete="listReset"
    ></addby-comments>

    <!-- 批量需改内容 -->
    <el-dialog
      title="批量修改内容"
      class="dialogVisible"
      width="800px"
      :visible.sync="recoverContentstatus"
      v-if="recoverContentstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="recoverContentCancel"
    >
      <div slot="title" class="flex-center">
        批量修改内容
        <div class="redcolor fs14 ml20">统一替换已选的发送人的内容</div>
      </div>
      <div class="dialogVisible_bottom">
        <el-input
          ref="contenInput"
          type="textarea"
          :rows="6"
          placeholder="输入对话内容"
          v-model="recoverContentVal"
          maxlength="200"
          show-word-limit
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="recoverContentCancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" @click="recoverContentSubmit" size="medium">
          保 存
        </el-button>
      </span>
    </el-dialog>

    <!-- 批量修改时长 -->
    <el-dialog
      title="批量修改内容"
      class="dialogVisible"
      width="422px"
      :visible.sync="recoverTimestatus"
      v-if="recoverTimestatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="recoverTimestatusCancel"
    >
      <div slot="title" class="flex-center">
        批量修改内容
        <div class="redcolor fs14 ml20">统一替换已选的发送人的内容</div>
      </div>
      <div class="dialogVisible_bottom">
        <span class="tips mr20">
          停顿时长(上限60秒)
          <helpIcon
            content="当前评论内容与下一条评论内容的发送间隔时长，比如：停顿时长设置5秒，当前评论发送后，等待5秒再发送下一条"
          ></helpIcon>
        </span>
        <el-input-number
          v-model="stop_time"
          :min="1"
          :max="60"
        ></el-input-number>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="recoverTimestatusCancel" size="medium">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="recoverTimestatusSubmit"
          size="medium"
        >
          保 存
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import addbyComments from './addbyComments'
import excelImport from './excelImport'
import addConversation from './addConversation/index'
export default {
  components: {
    addConversation,
    excelImport,
    addbyComments,
  },

  data() {
    return {
      // 批量修改时长
      stop_time: 1,
      // 批量修改评论时长
      recoverTimestatus: false,

      // 批量修改评论内容
      recoverContentVal: '',
      // 批量修改评论
      recoverContentstatus: false,

      // 按评论内容添加
      addbyCommentsStatus: false,

      // excel导入
      excelImportstatus: false,

      // 添加对话弹窗
      status: false,

      //  编辑的参数
      editList: [],

      post: {
        msg_id: this.msg_id,
        search_type: '1',
        search: '',
      },
      search: '',

      // 列表所选数据
      selectList: [],

      // 获取到的列表数据
      completeData: [],

      // 全选组件的状态
      isIndeterminate: false,

      // 单选框的数据
      overdue: 2,

      changeVal: {},
      changeType: '',
    }
  },

  filters: {
    getRole(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '真实学员 '
          break
        case 2:
          text = ' 自定义机器人'
          break
        case 3:
          text = '系统机器人'
          break
      }
      return text
    },
  },

  created() {
    this.post.msg_id = this.msg_id
  },

  props: {
    msg_id: {
      type: String,
      default: '',
    },
  },

  methods: {
    // 关闭保存批量修改时长
    recoverTimestatusCancel() {
      this.stop_time = 1
      this.recoverTimestatus = false
    },

    // 保存批量修改时长
    async recoverTimestatusSubmit() {
      const ids = this.selectList.map(item => item.chat_msg_talk_id)
      await this.$http({
        url: '/chatMessage/batchEditMsgTalk',
        data: {
          chat_msg_talk_ids: ids,
          stop_time: this.stop_time,
        },
        successMsg: true,
      })
      this.recoverTimestatusCancel()
      this.$refs.child.reset()
    },

    // 批量修改时长
    recoverTime() {
      if (this.selectList.length == 0) {
        this.$root.prompt('请先选择对话')
        return
      }
      this.recoverTimestatus = true
    },
    listReset() {
      this.$refs.child.reset()
    },

    // 批量修改内容弹窗保存
    async recoverContentSubmit() {
      const ids = this.selectList.map(item => item.chat_msg_talk_id)
      await this.$http({
        url: '/chatMessage/batchEditMsgTalk',
        data: {
          chat_msg_talk_ids: ids,
          content: this.recoverContentVal,
        },
        successMsg: true,
      })

      this.recoverContentCancel()
      this.listReset()
    },

    // 批量修改内容
    recoverContent() {
      if (this.selectList.length == 0) {
        this.$root.prompt('请先选择对话')
        return
      }
      this.recoverContentstatus = true
      this.$nextTick(() => {
        this.$refs.contenInput.focus()
      })
    },

    //  批量修改内容弹窗关闭
    recoverContentCancel() {
      this.recoverContentVal = ''
      this.recoverContentstatus = false
    },

    cancel() {
      this.editList = []
    },

    editContent(val) {
      this.editList.push(_.assign({}, val))
      this.status = true
    },

    addConversationComplete() {
      this.editList = []
      this.listReset()
    },

    addMessage() {
      this.status = true
    },
    // 上移下移
    async moveUpDown(id, type) {
      await this.$http({
        url: '/chatMessage/contentSort',
        data: {
          chat_msg_talk_id: id,
          sort: type,
        },
        successMsg: true,
      })
      this.listReset()
    },

    // 删除评论
    async del(type, val) {
      if (type == 2 && this.selectList.length == 0) {
        this.$root.prompt('请先选择对话')
        return
      }
      this.$confirm('确定删除已选对话？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/chatMessage/delMsgTalk',
            data: { chat_msg_talk_ids: val },
            successMsg: true,
          })
          this.listReset()
        })
        .catch(() => {})
    },

    toSearch() {
      this.post = _.assign({}, this.post, { search: this.search })
    },

    // 全选
    checkALL(val) {
      if (val == 1) {
        this.$refs.multipleTable.toggleAllSelection()
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },

    // 列表选择的数据
    handleSelectionChange(val) {
      this.selectList = val
      this.isIndeterminate =
        this.selectList.length > 0 &&
        this.completeData.length != this.selectList.length
      if (
        this.selectList.length > 0 &&
        this.completeData.length == this.selectList.length
      ) {
        this.overdue = 1
      } else {
        this.overdue = 2
      }
      this.$forceUpdate()
    },

    // 获取列表数据
    complete({ data, count }) {
      this.completeData = data
      this.count = count
    },

    resetChangeVal() {
      this.changeVal = {}
      this.changeType = ''
    },

    // 修改内容
    changeContent(row) {
      if (this.changeType && this.changeType != 1) {
        this.changeStopTime()
      }
      if (row) {
        row.old_content = row.content
        this.changeType = 1
        this.changeVal = row
      } else {
        this.changeVal.content = this.changeVal.old_content
        this.resetChangeVal()
      }
    },

    // 修改停顿时长
    changeStopTime(row) {
      if (this.changeType && this.changeType != 2) {
        this.changeContent()
      }
      if (row) {
        row.old_stop_time = row.stop_time
        this.changeType = 2
        this.changeVal = row
      } else {
        this.changeVal.stop_time = this.changeVal.old_stop_time
        this.resetChangeVal()
      }
    },

    // 保存停顿时长
    async saveMessage(row) {
      if (!row.content.trim()) {
        this.$root.prompt('请输入评论内容')
      } else {
        const ids = [row.chat_msg_talk_id]
        await this.$http({
          url: '/chatMessage/batchEditMsgTalk',
          data: {
            chat_msg_talk_ids: ids,
            stop_time: row.stop_time,
            content: row.content,
          },
          successMsg: true,
        })
        this.resetChangeVal()
        this.listReset()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.simulateConversation {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .el-divider {
    background: rgba(27, 157, 151, 1);
  }
  .tips2 {
    color: #ff3535;
    font-size: 14px;
    position: absolute;
    line-height: 18px;
    margin-top: 11px;
  }
  .tips {
    font-size: 14px;
    color: #333333;
    margin-left: 10px;
  }
  .pagination2 {
    margin-top: 20px;
    .robot_contain {
      display: flex;
      align-items: center;

      .tu {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        font-size: 14px;
        color: #333333;
        margin-left: 10px;
      }
    }
    .footer {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }
  }
}
</style>

<style lang="scss">
.simulateConversation_popper {
  width: 122px !important;
  min-width: auto;
  .popover_item {
    .item {
      text-align: center;
      font-size: 14px;
      color: #333333;
      line-height: 40px;
      cursor: pointer;
      &:hover {
        color: #0aa29b;
      }
    }
  }
}
</style>
