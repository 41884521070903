<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-10-24 18:37:07
 * @LastEditTime: 2022-11-03 18:59:31
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @Description: 添加对话
 * @FilePath: \teacherdingding.com\src\views\LiveBroadcastManagement\messageTemplate\details\addConversation\index.vue
-->
<template>
  <div class="addConversation">
    <el-dialog
      title="添加对话"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <el-button
          type="primary"
          @click="status = true"
          size="medium"
          class="mb20 mt10"
          v-if="!isedit"
        >
          添加发送人
        </el-button>
        <el-table
          style=" min-height: 300px;"
          height="400"
          empty-text="请先添加发送人"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tempplateContentList"
        >
          <el-table-column label="发送人" width="150">
            <template slot-scope="{ row }">
              <div class="sender_contain">
                <div class="tu">
                  <img :src="row.photo || row.uphoto" alt="" />
                </div>
                <div class="name">
                  {{ row.name || row.uname }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="发送内容">
            <template slot-scope="{ row }">
              <el-input
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 4 }"
                placeholder="输入对话内容"
                v-model="row.content"
                maxlength="200"
                show-word-limit
              ></el-input>
            </template>
          </el-table-column>

          <el-table-column label="停顿时长(上限60秒)" width="200">
            <div slot="header">
              停顿时长(上限60秒)
              <helpIcon
                content="当前评论内容与下一条评论内容的发送间隔时长，比如：停顿时长设置5秒，当前评论发送后，等待5秒再发送下一条"
              ></helpIcon>
            </div>
            <template slot-scope="{ row }">
              <div class=" robot_contain">
                <el-input-number
                  v-model="row.stop_time"
                  :min="1"
                  :max="60"
                ></el-input-number>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100" align="right">
            <template slot-scope="{ $index }">
              <el-button size="mini" type="text" @click="reset($index)">
                重置
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button size="mini" type="text" @click="remove($index)">
                移除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" @click="submit" size="medium">
          保 存
        </el-button>
      </span>
    </el-dialog>
    <!-- 添加发送人 -->
    <add-sender
      v-if="status"
      :dialogstatus.sync="status"
      @senderList="senderList"
    ></add-sender>
  </div>
</template>

<script>
import addSender from './addSender'
export default {
  components: {
    addSender,
  },

  data() {
    return {
      isedit: false,
      tempplateContentList: [],
      status: this.defaultStatus,
    }
  },

  watch: {
    status(val, old) {
      // 从显示切换到隐藏 但是没数据传递过来 说明点的是取消按钮
      if (old && !val && this.tempplateContentList.length === 0) {
        this.cancel()
      }
    },
  },

  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },
    defaultStatus: {
      type: Boolean,
      default: true,
    },
    // 默认编辑状态 true，false为非编辑状态
    editStatus: {
      type: Boolean,
      default: true,
    },
    msg_id: {
      type: String,
      default: '',
    },
    editList: {
      type: Array,
      default: undefined,
    },
  },

  created() {
    const val = this.editList.length
    if (val > 0) {
      if (this.editStatus) {
        this.isedit = true
      }
      this.tempplateContentList.push(...this.editList)
    }
  },

  methods: {
    // 确定
    async submit() {
      const emptycCntent =
        this.tempplateContentList.findIndex(item => !item.content) > -1
      const val = _.assign(
        {},
        { msg_id: this.msg_id, list: this.tempplateContentList }
      )
      if (emptycCntent) {
        this.$root.prompt('请输入对话')
        return
      }
      if (this.isedit) {
        await this.$http({
          url: '/chatMessage/editMsgTalk',
          data: { list: this.tempplateContentList },
          successMsg: true,
        })
      } else {
        await this.$http({
          url: '/chatMessage/addMsgTalk',
          data: val,
          successMsg: true,
        })
      }

      this.cancel()

      this.$emit('addConversationComplete')
    },

    // 移除
    remove(index) {
      this.tempplateContentList.splice(index, 1)
    },

    // 重置数据
    reset(index) {
      this.tempplateContentList[index].content = ''
      this.tempplateContentList[index].stop_time = 1
      this.$forceUpdate()
    },

    // 获取选择到的机器人数据
    senderList(val) {
      const data = val.map(item => {
        return {
          ...item,
          content: '',
          stop_time: 1,
        }
      })
      this.tempplateContentList.push(...data)
    },

    cancel() {
      this.tempplateContentList = []
      this.isedit = false
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="scss" scoped>
.addConversation {
  ::v-deep .el-dialog__body {
    padding: 0 20px 20px;
  }
  .dialogVisible_bottom {
    img {
      width: 100%;
      height: 100%;
    }
    .sender_contain {
      display: flex;
      align-items: center;
      .tu {
        width: 20px;
        height: 20px;
        border-radius: 4px;
      }
      .name {
        font-size: 14px;
        color: #333333;
        margin-left: 10px;
      }
    }
  }
}
</style>
