<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-10-25 10:23:50
 * @LastEditTime: 2022-11-04 17:18:23
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @Description: 添加发送人
 * @FilePath: \teacherdingding.com\src\views\LiveBroadcastManagement\messageTemplate\details\addConversation\addSender.vue
-->
<template>
  <div class="addSender">
    <el-dialog
      title="选择发送人"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <!-- 顶部搜索 -->
        <div class="header mb10">
          <el-button
            type="text"
            @click="reset"
            style="position: absolute;right: 50px;top: 9px;font-size: 13px;"
          >
            刷新列表
          </el-button>
          <span class="tips mr10">分组</span>
          <el-select
            clearable
            class="mr20"
            v-model="formData.gid"
            style="width:150px "
            size="medium"
            placeholder="请选择"
          >
            <el-option
              v-for="item in selectGroupList"
              :key="item.gid"
              :label="item.gname"
              :value="item.gid"
            ></el-option>
          </el-select>

          <span class="tips mr10">关键词</span>
          <el-input
            clearable
            @keyup.enter.native="toSearch"
            style="width: 300px"
            size="medium"
            v-model="formData.search"
            class="input-with-select  "
            :placeholder="
              formData.search_type == 2 || formData.search_type == ''
                ? '输入机器人名称搜索'
                : '输入机器人ID搜索'
            "
          >
            <el-select
              size="medium"
              v-model="formData.search_type"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="机器人名称" value="2"></el-option>
              <el-option label="机器人ID" value="1"></el-option>
            </el-select>
          </el-input>
          <el-button
            type="primary"
            class="ml20"
            @click="toSearch"
            style="width:74px"
            size="medium"
          >
            搜索
          </el-button>
        </div>
        <!-- 表格 -->
        <pagination2
          ref="child"
          :option="formData2"
          url="/liveRobot/robotList"
          @complete="complete"
          class="pagination2"
        >
          <template>
            <el-table
              ref="multipleTable"
              height="400"
              :data="completeData"
              @row-click="rowClick"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="45px"></el-table-column>
              <el-table-column prop="robot_id" label="机器人ID" width="100px" />
              <el-table-column label="机器人">
                <template slot-scope="{ row }">
                  <div class=" robot_contain">
                    <div class="tu">
                      <img :src="row.photo" alt="" />
                    </div>
                    <div class="name hidetext">{{ row.name }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="分组名称">
                <template slot-scope="{ row }">
                  {{ row.gname || '-' }}
                </template>
              </el-table-column>
              <el-table-column label="创建人" prop="uname">
                <template slot-scope="{ row }">
                  {{ row.uname || '-' }}
                </template>
              </el-table-column>
              <el-table-column label="创建时间">
                <template slot-scope="{ row }">
                  {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
                </template>
              </el-table-column>
            </el-table>
          </template>
          <template #footer>
            <div class="footer">
              <el-checkbox
                v-model="overdue"
                :true-label="1"
                :false-label="2"
                class="ml10"
                :indeterminate="isIndeterminate"
                @change="checkALL"
              >
                <div class="tips ml10">已选择{{ selectList.length }}条</div>
              </el-checkbox>
            </div>
          </template>
        </pagination2>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium">
          取消
        </el-button>
        <el-button type="primary" @click="submit" size="medium">
          下一步
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      radioId: '',
      selectGroupList: [],
      completeData: [],
      isIndeterminate: false,
      selectList: [],
      overdue: 2,
      formData: {
        gid: '0',
        search_type: '2',
        search: '',
        sort: '1',
        is_page: 1,
      },
      formData2: {
        gid: '',
        search_type: '2',
        search: '',
        sort: '1',
        is_page: 1,
      },
    }
  },

  created() {
    this.getGroupList()
  },

  methods: {
    // 确定
    submit() {
      if (this.selectList.length === 0) {
        this.$root.prompt('请选择发送人')
      } else {
        this.$emit('senderList', this.selectList)
        this.cancel()
      }
    },

    // 点击列表行的事件
    rowClick(row) {
      this.radioId = row.robot_id
      this.$refs.multipleTable.toggleRowSelection(row)
    },

    // 刷新
    reset() {
      this.$refs.child.reset()
    },

    // 搜索
    toSearch() {
      this.formData2 = _.assign({}, this.formData)
    },

    // 获取分组数据
    async getGroupList() {
      const { data } = await this.$http({
        url: '/pcUser/getGroupList',
        data: { user_type: 2, type: 2 },
      })
      this.selectGroupList = _.merge([], data)
      this.selectGroupList.unshift({
        gid: '0',
        gname: '全部',
      })
    },

    // 获取列表数据
    complete({ data, count }) {
      this.completeData = data
      this.count = count
    },

    // 全选
    checkALL(val) {
      if (val == 1) {
        this.$refs.multipleTable.toggleAllSelection()
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },

    // 列表选择的数据
    handleSelectionChange(val) {
      this.selectList = val
      this.isIndeterminate =
        this.selectList.length > 0 &&
        this.completeData.length != this.selectList.length
      if (
        this.selectList.length > 0 &&
        this.completeData.length == this.selectList.length
      ) {
        this.overdue = 1
      } else {
        this.overdue = 2
      }
      this.$forceUpdate()
    },
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.addSender {
  img {
    width: 100%;
    height: 100%;
  }
  .tips {
    font-size: 14px;
    color: #333333;
    margin-left: 10px;
  }
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: right;

    ::v-deep .input-with-select {
      .el-input__inner {
        color: #333333;
      }
      .el-input-group__prepend {
        background-color: #fff;
        width: 80px;
      }
    }
  }
  .el-divider {
    background: rgba(27, 157, 151, 1);
  }
  .pagination2 {
    .robot_contain {
      display: flex;
      align-items: center;

      .tu {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        font-size: 14px;
        color: #333333;
        margin-left: 10px;
      }
    }
    .footer {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }
  }
}
</style>
