<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-10-25 15:01:50
 * @LastEditTime: 2022-11-04 17:32:14
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @Description: 按评论内容添加
 * @FilePath: \teacherdingding.com\src\views\LiveBroadcastManagement\messageTemplate\details\addbyComments.vue
-->
<template>
  <div class="addbyComments">
    <el-dialog
      title="按评论内容添加"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <div class="item mb20 flex-center mt10">
          <el-popover
            popper-class="simulateConversation_popper"
            placement="bottom"
            width="122"
            trigger="click"
          >
            <div class="popover_item">
              <div class="item" @click="libraryStatus = true">
                从机器人库选择
              </div>
              <div class="item" @click="groupsStatus = true">
                从机器人分组选择
              </div>
            </div>
            <el-button
              type="primary"
              :disabled="robotList.length >= limit"
              slot="reference"
              size="medium"
            >
              选择发送人来源
              <i class="el-icon-arrow-down"></i>
            </el-button>
          </el-popover>
          <span class="tips ml20 mr30">
            已选来源：{{ robotList.length }}/{{ limit }}
          </span>
          <el-tag
            :key="tag.gid"
            v-for="(tag, index) in robotList"
            closable
            :disable-transitions="false"
            @close="handleClose(index)"
          >
            <span :title="tag.name || tag.gname">
              {{ tag.name || tag.gname }}
            </span>
          </el-tag>
        </div>

        <div class="mb20">
          <span class="tips mr20" style="font-weight: bold;">
            停顿时长(上限60秒)
            <helpIcon
              content="当前评论内容与下一条评论内容的发送间隔时长，比如：停顿时长设置5秒，当前评论发送后，等待5秒再发送下一条"
            ></helpIcon>
          </span>
          <el-input-number
            v-model="stop_time"
            :min="1"
            :max="60"
            size="small"
          ></el-input-number>
        </div>
        <div class="item">
          <el-input
            type="textarea"
            rows="12"
            maxlength="200"
            autofocus
            show-word-limit
            placeholder="请输入发言内容"
            :value="templateMessage"
            @input="templateMessageChange"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="tips2">
          温馨提示：单个内容字数不可超过200个，添加多个时可回车换行添加
        </div>
        <el-button @click="cancel" size="medium">
          取消
        </el-button>
        <el-button type="primary" @click="submit" size="medium">
          保存
        </el-button>
      </span>
    </el-dialog>

    <!-- 从机器人库选择 -->
    <library
      v-if="libraryStatus"
      :limit="limit"
      :allowance="allowance"
      :gids="gids"
      @robotselectComplete="robotselectComplete"
      :dialogstatus.sync="libraryStatus"
    ></library>

    <!-- 从机器人分组选择 -->
    <groups
      v-if="groupsStatus"
      :dialogstatus.sync="groupsStatus"
      :limit="limit"
      :allowance="allowance"
      :gids="gids"
      @robotselectComplete="robotselectComplete"
    ></groups>

    <!-- 添加对话 -->
    <!-- <add-conversation
      v-if="conversationStatus"
      :dialogstatus.sync="conversationStatus"
      :editList="commentuserList"
      :editStatus="editStatus"
      :msg_id="msg_id"
      :defaultStatus="false"
      @addConversationComplete="addConversationComplete"
    ></add-conversation> -->
  </div>
</template>

<script>
// import  groups from ''
// import addConversation from './addConversation/index'
import groups from './robotSelect/groups'
import library from './robotSelect/library'
export default {
  components: {
    library,
    groups,
    // addConversation,
  },

  computed: {
    allowance() {
      return this.robotList.length
    },

    gids() {
      return this.robotList.map(item => item.gid)
    },
  },

  props: {
    msg_id: {
      type: String,
      default: '',
    },

    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // editStatus: false,
      // 下一步的添加评论
      // conversationStatus: false,
      // 获取到的发送人列表
      // commentuserList: [],

      // 从分组选择机器人
      groupsStatus: false,

      // 选择的机器人库或者机器人分组列表
      robotList: [{ gid: '-1', name: '系统默认', status: '1', type: '1' }],
      // 限制选择的发送人个数
      limit: 3,
      // 机器人库选择
      libraryStatus: false,

      dynamicTags: ['标签一', '标签二', '标签三'],

      templateMessage: '',

      options: [],

      value: '',

      stop_time: 1,
    }
  },

  methods: {
    addConversationComplete() {
      this.$emit('addbyCommentsComplete')
      this.cancel()
    },

    robotselectComplete(val) {
      this.robotList.push(...val)
    },

    // 删除已选标签
    handleClose(index) {
      this.robotList.splice(index, 1)
    },
    // 添加消息内容
    async submit() {
      if (!this.templateMessage.trim()) {
        this.$root.prompt('请输入发言内容，回车换行可添加多个')
        return
      }

      if (this.robotList.length == 0) {
        this.$root.prompt('请先选择发送人来源')
        return
      }
      const result = this.templateMessage.split('\n').filter(item => !!item)
      const { data } = await this.$http({
        url: '/chatMessage/getCommentUser',
        data: {
          gids: this.gids,
          content: result,
        },
      })
      this.$http({
        url: '/chatMessage/addMsgTalk',
        data: {
          list: data.map(item =>
            _.assign({}, item, { stop_time: this.stop_time })
          ),
          msg_id: this.$route.query.msg_id,
        },
        successMsg: true,
      })
      this.addConversationComplete()
      // this.commentuserList = data
      // this.editStatus = false

      // this.$nextTick(() => {
      //   this.conversationStatus = true
      // })
    },

    // 消息内容变化
    templateMessageChange(value) {
      this.templateMessage = value
        .split('\n')
        .map(item => item.slice(0, 200))
        .join('\n')
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.addbyComments {
  ::v-deep .el-tag {
    background-color: #323232;
    border-color: #323232;
    height: 25px;
    line-height: 25px;
    max-width: 141px;
    font-size: 12px;
    color: #fff;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    padding-right: 20px;

    .el-tag__close {
      color: #fff;
      &:hover {
        background-color: #323232;
      }
    }
    .el-icon-close {
      position: absolute;
      top: 5px;
      right: 2px !important;
    }
  }
  .el-tag--light {
    margin-right: 10px;
  }
  .tips2 {
    color: #ff3535;
    font-size: 14px;
    position: absolute;
    line-height: 18px;
    margin-top: 11px;
  }
  .tips {
    font-size: 14px;
    color: #333333;
  }
  ::v-deep .el-dialog__body {
    padding: 0 20px 20px;
  }
}
</style>
