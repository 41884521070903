<!--
 * @Author: cyy
 * @Date: 2022-03-22 11:06:14
 * @LastEditTime: 2022-11-07 19:13:24
 * @LastEditors: cyy
 * @Description: excel导入模板
 * @FilePath: \teacherdingding.com\src\views\LiveBroadcastManagement\messageTemplate\components\localImport.vue
-->

<template>
  <div id="localImport">
    <el-dialog
      title="从Excel导入添加"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <div class="tip">
          提示：仅支持Excel格式文件，单次上传大小不超过10M，行数不超过1万，若超出限制，请分批导入；为避免乱码或导入异常，请使用Office2010或以上版本，请严格按照模板内容填写数据
        </div>
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          label-position="left"
        >
          <!-- <el-form-item label="模板名称" :required="true">
            <el-select
              v-model="ruleForm.msg_id"
              style="width: 383px"
              placeholder="请选择模板"
              filterable
              @focus="getTempplateList"
            >
              <el-option
                v-for="item in tempplateList"
                :key="item.msg_id"
                :label="item.name"
                :value="item.msg_id"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="选择文件" :required="true">
            <input
              ref="inputExcel"
              type="file"
              v-show="false"
              class="file"
              @change="fileChange"
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
            <el-button
              type="primary"
              class="mr20"
              style="width: 158px"
              @click="selectFile"
            >
              上传文件
            </el-button>
            <a href="https://f.dingdingkaike.com.cn/excelTemplate/liveMsg.xlsx">
              <!-- 下载消息模板 -->
              <el-button type="text" @click.self.prevent>
                下载消息模板
              </el-button>
            </a>
            <div class="fs14" v-if="file">已选择文件:{{ file.name }}</div>
          </el-form-item>
          <el-form-item label="导入方式" :required="true">
            <el-radio-group v-model="ruleForm.import_type">
              <el-radio :label="2">
                合并
                <helpIcon
                  content="不清除原有记录，从最后一条记录开始插入，列表显示会按发送时间正序排序"
                ></helpIcon>
              </el-radio>
              <el-radio :label="1">
                覆盖
                <helpIcon content="先清除原有的评论内容，再添加新的"></helpIcon>
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" style="width: 74px" size="medium">
          取消
        </el-button>
        <el-button
          type="primary"
          @click="submit"
          size="medium"
          style="width: 74px"
        >
          确定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'localImport',

  data() {
    return {
      file: null,

      tempplateList: [],

      ruleForm: {
        msg_id: this.msg_id,
        import_type: 2,
      },

      dialogVisible: false,
    }
  },

  props: {
    tempplateListfromHistory: {
      type: Array,
      default: null,
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
    msg_id: {
      type: String,
      default: '',
    },
  },

  created() {
    // 初始值从父组件获取，原因：如果在组件获取，需要请求数据，在组件渲染时，数据可能没有获取到，导致展示有误或延迟
    if (this.tempplateList) {
      this.tempplateList = this.tempplateListfromHistory
    } else {
      this.getTempplateList()
    }
    this.ruleForm.msg_id = this.msg_id
  },

  methods: {
    // 触发input
    selectFile() {
      this.$refs.inputExcel.click()
    },

    // 提交
    submit() {
      // if (!this.ruleForm.msg_id) {
      //   this.$root.prompt('请选择模板')
      //   return
      // }
      if (!this.file) {
        this.$root.prompt('请选择文件')
        return
      }
      let param = new FormData()
      param.append('file', this.file)
      param.append('msg_id', this.ruleForm.msg_id)
      param.append('import_type', this.ruleForm.import_type)

      var request = new XMLHttpRequest()

      request.open('post', `${location.origin}/ChatMessage/excel_runimport`)
      request.send(param)
      request.onload = () => {
        this.$root.prompt({
          msg: '操作成功',
          type: 'success',
        })
        this.$refs.inputExcel.value = ''
        this.$emit('fileChangeComplete')
        this.cancel()
      }
    },

    // 选择文件后提交
    fileChange(e) {
      const file = e.target.files[0]
      const maxSize = 10
      if (file.size > 1024 * 1024 * maxSize) {
        this.$root.prompt('上传文件大于10M')
        return
      }
      this.file = file
      e.target.value = ''
    },

    // 模板列表
    async getTempplateList() {
      const { data } = await this.$http({
        url: '/chatMessage/templateList',
        data: {},
      })
      this.tempplateList = data
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>
<style lang="scss" scoped>
#localImport {
  .dialogVisible {
    .dialogVisible_bottom {
      .tip {
        font-size: 14px;
        color: #666666;
        line-height: 1.5;
        margin-bottom: 30px;
      }
    }
  }
}
</style>
