<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-10-25 17:01:49
 * @LastEditTime: 2022-10-31 10:29:52
 * @LastEditors: cyy
 * @Description: 从机器人库选择
 * @FilePath: \teacherdingding.com\src\views\LiveBroadcastManagement\messageTemplate\details\robotSelect\library.vue
-->
<template>
  <div class="library">
    <el-dialog
      title="从机器人库选择"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <pagination2
          :option="post"
          url="/liveRobot/robotLibrary"
          ref="childDialogBox"
        >
          <template v-slot:default="{ tableData }">
            <el-table
              ref="dialogTable"
              @row-click="rowClick"
              @selection-change="handleSelectionChange"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              height="400"
              :data="tableData"
            >
              <el-table-column type="selection" width="45px"></el-table-column>
              <el-table-column
                label="已开通的机器人库"
                prop="name"
              ></el-table-column>
            </el-table>
          </template>
        </pagination2>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" @click="submit" size="medium">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    // 已经选择的id
    gids: {
      type: Array,
      default: () => [],
    },

    // 限制选择的个数
    limit: {
      type: Number,
      default: 0,
    },

    // 已经选择的个数
    allowance: {
      type: Number,
      default: 0,
    },

    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      post: {
        status: 1,
        gids: this.gids,
      },
    }
  },

  created() {
    this.post.gids = this.gids
  },

  methods: {
    handleSelectionChange(val) {
      this.selectList = val
    },

    submit() {
      if (this.allowance + this.selectList.length > this.limit) {
        this.$root.prompt('总数量超过3个')
        return
      }
      this.$emit('robotselectComplete', this.selectList)
      this.cancel()
    },

    rowClick(row) {
      this.$refs.dialogTable.toggleRowSelection(row)
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.library {
  ::v-deep .el-dialog__body {
    padding: 0 20px 20px;
  }
}
</style>
