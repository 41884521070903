<!--
 * @Author: cyy
 * @Date: 2022-01-24 11:10:26
 * @LastEditTime: 2022-12-15 17:37:48
 * @LastEditors: cyy
 * @Description:聊天记录
 * @FilePath: \teacherdingding.com\src\views\LiveBroadcastManagement\messageTemplate\details\chatRecord.vue
-->
<template>
  <div id="messageContainer">
    <addVideo
      ref="addVideo"
      @setVideoInfo="setVideoInfo"
    ></addVideo>
    <div class="messageTemplate">
      <div class="add-template">
        <div class="flex-center">
          <el-popover
            popper-class="chatRecord_popper"
            placement="bottom"
            width="122"
            trigger="click"
          >
            <div class="popover_item">
              <div class="item" @click="onputExcel">从Excel导入添加</div>
              <div class="item" @click="importTemplate">
                从公开课导入添加
              </div>
              <div class="item" :class="[!videoInfo.video_url&&'noClick']" @click="addContent">
                手动添加
              </div>
            </div>
            <el-button type="primary" slot="reference" size="medium">
              添加评论
              <i class="el-icon-arrow-down"></i>
            </el-button>
          </el-popover>
  
          <div class="tem-name">
            <div class="btn_contain">
              <template v-if="dataStatus == 1">
                <el-button type="text" style="color:#FF3535;">
                  导入中...
                </el-button>
                <div class="driver">|</div>
              </template>
              <el-button type="text" @click="fileChangeComplete">
                刷新
              </el-button>
  
              <div class="driver">|</div>
              <el-button type="text" @click="loadLocation">
                导出全部
              </el-button>
            </div>
          </div>
        </div>
  
        <div class="timepicker">
          <!-- <span class="tips mr10">角色</span>
          <el-select
            size="medium"
            v-model="post.user_role"
            class="mr20"
            style="width: 140px"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select> -->
  
          <!-- <el-select
            v-model="post.type"
            size="medium"
            style="width: 140px"
            placeholder="请选择"
            @change="typeChange"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select> -->
  
          <el-time-picker
            v-if="post.type == 1"
            style="width: 240px"
            is-range
            @change="changeTimePicker"
            @input="changeTimePickerBlur"
            size="medium"
            :value="timeSearch"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            value-format="timestamp"
          ></el-time-picker>
  
          <el-date-picker
            v-else
            popper-class="chatRecordpopperClass"
            @change="datepickerChange"
            v-model="datepicker"
            style="width: 240px;"
            size="medium"
            type="datetimerange"
            range-separator="至"
            value-format="timestamp"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
  
          <el-button type="primary" class="ml10" size="medium" @click="toSearch">
            搜索
          </el-button>
        </div>
      </div>
      <div class="template-copntain">
        <div class="template-detail">
          <pagination2
            :option="post2"
            url="/chatMessage/contentList"
            ref="childDialogBox"
            @complete="complete"
            :hadPagination="true"
          >
            <template v-slot:default="{}">
              <el-table
                class="chatTable"
                ref="multipleTable"
                :fit="true"
                @selection-change="selectionChange"
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
                style="width: calc(100vw - 440px) !important;"
                :data="tempplateContentList"
                >
                <!-- v-loadmore="loadTable" -->
                <el-table-column type="selection"></el-table-column>
                <el-table-column label="发送时间" width="110">
                  <template slot-scope="{ row, $index }">
                    <div class="select-send-time">
                      <el-time-picker
                        @change="timePickerChange($event, row)"
                        :clearable="false"
                        :key="$index"
                        prefix-icon="el-icon-edit"
                        v-model="tempplateContentList[$index].s_time"
                        value-format="HH:mm:ss"
                      ></el-time-picker>
                      <img class="cp" src="~@ass/img/1.4.44/videoPoints.png" title="视频定位" alt="" style="height: 20px;width: 20px;" @click="$refs.addVideo.moveToPoint(row.s_time_copy)"/>
                    </div>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="用户ID" prop="user_id"></el-table-column> -->
                <el-table-column label="评论内容" min-width="170">
                  <template slot-scope="{ row }">
                    <div class="" v-if="row.msg_type == 1">
                      <!-- <i class="el-icon-edit"></i> -->
                      <span v-html="row.content" class="ml10"></span>
                    </div>
                    <div class="cp" style="max-height: 50px;max-width:50px;" v-else>
                      <el-image class="lazy-img" :src="row.content" lazy :preview-src-list="[row.content]"></el-image>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="发送人" width="150">
                  <template slot-scope="{ row }">
                    <div class="people-contain">
                      <div class="cover-img">
                        <img :src="row.uphoto" alt="" />
                      </div>
                      <div class="name">
                        {{ row.uname }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="角色">
                  <template slot-scope="{ row }">
                    {{ row.user_role | getrole }}
                  </template>
                </el-table-column> -->
                <el-table-column label="消息状态" width="80">
                  <template slot-scope="{ row }">
                    <span class="cp" :style="`color:${row.is_shield==2?'#19982B':'#FF3535'};`" @click="SOrHContent(row.msg_content_id,row.is_shield==1?2:1)">{{ row.is_shield | getMesStatus }}</span>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="添加时间">
                  <template slot-scope="{ row }">
                    <span v-if="row.c_time">
                      {{ row.c_time | formatTimeStamp }}
                    </span>
                    <span v-else>-</span>
                  </template>
                </el-table-column> -->
                <el-table-column label="操作" align="center" prop="address" width="90">
                  <template slot-scope="{ row }">
                    <!-- <el-popover
                      popper-class="chatRecord_popper chatRecord_popper2"
                      placement="bottom"
                      width="122"
                      trigger="click"
                    >
                      <div class="popover_item">
                        <div class="item" @click="editTemplateContent(row)">编辑</div>
                        <div class="item" @click="SOrHTemplateContent(row)">
                          <span>{{ row.is_shield==1?'显示':'隐藏' }}</span>
                        </div>
                        <div class="item" @click="delTemplateContent(row)">
                          删除
                        </div>
                      </div>
                      <el-button type="text" slot="reference" size="medium">
                        更多
                        <i class="el-icon-arrow-down"></i>
                      </el-button>
                    </el-popover> -->
                    <div class="caozuo">
                      <el-button
                        slot="reference"
                        type="text"
                        @click="editTemplateContent(row)"
                      >
                        编辑
                      </el-button>
                      <el-divider direction="vertical"></el-divider>
                      <!-- <el-button
                        slot="reference"
                        type="text"
                        @click="SOrHTemplateContent(row)"
                      >
                        <span>{{ row.is_shield==1?'显示':'隐藏' }}</span>
                      </el-button> -->
                      <el-button
                        slot="reference"
                        type="text"
                        @click="delTemplateContent(row)"
                      >
                        删除
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="footer mt20" slot="footer">
                <el-checkbox
                  v-model="overdue"
                  :true-label="1"
                  :false-label="2"
                  class="ml10"
                  :indeterminate="isIndeterminate"
                  @change="checkALL"
                >
                  <div class="tips">已选择{{ selectList.length }}条</div>
                </el-checkbox>

                <!-- <el-checkbox
                  v-model="is_all"
                  :true-label="1"
                  :false-label="2"
                  class="ml10"
                  :indeterminate="isIndeterminate"
                  @change="checkIsALL"
                >
                  <div class="tips">全选{{ is_all==1?(count-(tempplateContentList.length-selectList.length)):0 }}条</div>
                </el-checkbox> -->

                <el-popover
                  popper-class="chatRecord_popper chatRecord_popper3"
                  placement="top"
                  width="122"
                  trigger="click"
                >
                  <div class="popover_item">
                    <div class="item" @click="SOrHTemplateContent(null,2)">批量隐藏</div>
                    <div class="item" @click="SOrHTemplateContent(null,1)">批量显示</div>
                    <div class="item" @click="delTemplateContent()">批量删除</div>
                    <div class="item" @click="showchagetimedialog()">批量调整发送时间</div>
                  </div>
                  <el-button slot="reference" size="medium" class="ml20" :disabled="selectList==0">
                    批量
                    <i class="el-icon-arrow-up"></i>
                  </el-button>
                </el-popover>

                <el-popover
                  popper-class="chatRecord_popper chatRecord_popper3"
                  placement="top"
                  width="122"
                  trigger="click"
                >
                  <div class="popover_item">
                    <div class="item" @click="SOrHTemplateContent(null,2,1)">全部隐藏</div>
                    <div class="item" @click="SOrHTemplateContent(null,1,1)">全部显示</div>
                    <div class="item" @click="delTemplateContent(null,1)">全部删除</div>
                    <div class="item" @click="showchagetimedialog(1)">全部调整发送时间</div>
                  </div>
                  <el-button slot="reference" size="medium" class="ml20" :disabled="selectList!=0">
                    全选
                    <i class="el-icon-arrow-up"></i>
                  </el-button>
                </el-popover>

                <!-- <el-button
                  slot="reference"
                  style="width: 100px"
                  class="ml20"
                  size="medium"
                  :disabled="selectList.length == 0"
                  @click="SOrHTemplateContent(null,2)"
                >
                  批量隐藏
                </el-button>
                <el-button
                  slot="reference"
                  style="width: 100px"
                  class="ml20"
                  size="medium"
                  :disabled="selectList.length == 0"
                  @click="SOrHTemplateContent(null,1)"
                >
                  批量显示
                </el-button>
                <el-button
                  slot="reference"
                  style="width: 100px"
                  class="ml20"
                  size="medium"
                  :disabled="selectList.length == 0"
                  @click="delTemplateContent"
                >
                  批量删除
                </el-button>
                <el-button
                  class="ml20"
                  slot="reference"
                  style="width: 150px"
                  size="medium"
                  @click="showchagetimedialog"
                  :disabled="selectList.length == 0"
                >
                  批量调整发送时间
                </el-button> -->
              </div>
            </template>
          </pagination2>
        </div>
      </div>
      <loading v-show="isNoData" leftPos="0"></loading>
      <!-- 批量调整时间 -->
      <el-dialog
        title="批量调整发送时间"
        :visible.sync="changeMoreTime"
        width="422px"
      >
        <div class="change-more-time">
          <el-row>
            <el-col :span="4">
              <div class="grid-content bg-purple">发送时间</div>
            </el-col>
            <el-col :span="20">
              <div class="grid-content bg-purple-light">
                <el-radio-group v-model="changetimeradio">
                  <el-radio :label="1">前移</el-radio>
                  <el-radio :label="2">后移</el-radio>
                </el-radio-group>
                <div class="time">
                  <el-input-number
                    :step="1"
                    :step-strictly="true"
                    size="medium"
                    v-model="changetime[0]"
                    controls-position="right"
                    :min="0"
                    :max="23"
                  ></el-input-number>
                  <span style="margin: 0 5px">时</span>
  
                  <el-input-number
                    :step="1"
                    :step-strictly="true"
                    size="medium"
                    v-model="changetime[1]"
                    controls-position="right"
                    :min="0"
                    :max="59"
                  ></el-input-number>
                  <span style="margin: 0 5px">分</span>
  
                  <el-input-number
                    :step="1"
                    :step-strictly="true"
                    size="medium"
                    v-model="changetime[2]"
                    controls-position="right"
                    :min="0"
                    :max="59"
                  ></el-input-number>
                  <span style="margin: 0 5px">秒</span>
                </div>
                <div class="redcolor fs12">
                  提示：将{{is_all==1?'全部':'选中的'}}消息发送时间整体移动
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelToChangeMoreTime">取 消</el-button>
          <el-button type="primary" @click="toChangeMoreTime">确 定</el-button>
        </span>
      </el-dialog>
  
      <!-- 新建模板 -->
      <el-dialog title="新建模板" :visible.sync="showAddTemplate" width="422px">
        <el-input
          type="text"
          placeholder="请输入模板名称"
          v-model="templateName"
          maxlength="30"
          show-word-limit
        ></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelshowAddTemplate">取 消</el-button>
          <el-button type="primary" @click="submitShowAddTemplate">
            确 定
          </el-button>
        </span>
      </el-dialog>
  
      <!--添加模板-->
      <el-dialog
        title="从公开课导入添加"
        :visible.sync="showImportTemplate"
        :close-on-click-modal="false"
        width="850px"
      >
        <div style="margin: -20px 0 0 0">
          <el-form
            :model="ruleForm"
            ref="ruleForm"
            label-width="85px"
            class="demo-ruleForm"
            label-position="left"
          >
            <el-form-item label="公开课" :required="true">
              <span slot="label" style="margin-right: 15px">公开课</span>
              <el-select
                filterable
                @focus="opencourseFocus"
                @change="changeOpencourse"
                v-model="ruleForm.open_class_id"
                style="width: 383px"
                placeholder="请选择公开课"
              >
                <el-option
                  v-for="(item, index) in allOpenList"
                  :key="index"
                  :label="item.name"
                  :value="item.open_class_id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="选择内容"
              :required="true"
              v-if="ruleForm.open_class_id"
            >
              <el-radio-group v-model="ruleForm.type" @change="reset">
                <el-radio :label="1">按直播记录选</el-radio>
                <el-radio :label="2">按自定义时段选</el-radio>
              </el-radio-group>
              <div v-if="ruleForm.type == 1">
                <el-select
                  filterable
                  v-model="ruleForm.live_history_id"
                  style="width: 383px"
                  placeholder="选择直播记录"
                  @change="getMsgCount"
                >
                  <el-option
                    v-for="(item, index) in allHistoryList"
                    :key="index"
                    :label="getlabel(item)"
                    :value="item.live_history_id"
                  ></el-option>
                </el-select>
              </div>
              <div v-if="ruleForm.type == 2">
                <el-date-picker
                  v-model="ruleForm.date"
                  class="mr10"
                  :picker-options="pickerOptions"
                  type="date"
                  value-format="timestamp"
                  placeholder="选择日期"
                  @change="timePickerChange2"
                ></el-date-picker>
                <el-time-picker
                  is-range
                  v-model="ruleForm.time"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  placeholder="选择时间范围"
                  value-format="H:m:s"
                  @change="timePickerChange2"
                ></el-time-picker>
              </div>
              <div class="fs14" style="color: #666666">
                <span v-if="loadding">条数计算中...</span>
                <span v-else>共{{ msgCount }}条信息</span>
              </div>
            </el-form-item>
            <el-form-item label="导入方式" :required="true">
              <el-radio-group v-model="ruleForm.import_type">
                <el-radio :label="2">
                  合并
                  <helpIcon
                    content="不清除原有记录，从最后一条记录开始插入，列表显示会按发送时间正序排序"
                  ></helpIcon>
                </el-radio>
                <el-radio :label="1">
                  覆盖
                  <helpIcon content="先清除原有的评论内容，再添加新的"></helpIcon>
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="importTemplate">取消</el-button>
          <el-button type="primary" @click="importTemplateSave">导入</el-button>
        </span>
      </el-dialog>
  
      <!--添加消息内容-->
      <el-dialog
        title="添加消息内容"
        :visible.sync="showAddMessage"
        :close-on-click-modal="false"
        width="850px"
      >
        <div slot="title" class="flex-center">
          <div class="t1" style="font-size: 16px; color: #333333">
            添加消息内容
          </div>
          <div
            v-if="selectTemplate"
            class="t2"
            style="font-size: 12px; color: #666666; margin-left: 40px"
          >
            添加到模板：{{ selectTemplate.name }}
          </div>
        </div>
        <div style="margin: -20px 0 0 0">
          <el-input
            type="textarea"
            rows="18"
            placeholder="请输入发言内容"
            :value="templateMessage"
            @input="templateMessageChange"
          ></el-input>
        </div>
        <div slot="footer" class="dialog-footer">
          <div
            style="color: #ff3535;
                font-size: 14px;
                position: absolute;
                line-height: 18px;
                margin-top: 11px;
              "
          >
            温馨提示：单个内容字数不可超过200个，添加多个时可回车换行添加
          </div>
          <el-button @click="addMessage">取消</el-button>
          <el-button type="primary" @click="templateMessageSave">保存</el-button>
        </div>
      </el-dialog>
  
      <!--添加消息-->
      <el-dialog
        :title="sendContentInfo.msg_content_id?'编辑消息':'添加消息'"
        :visible.sync="showContent"
        width="850px"
        @close="closeContent"
      >
        <div style="margin: -20px 0 0 0;min-height: 280px;">
          <el-form
            :model="ruleForm"
            ref="ruleForm"
            label-width="120px"
            class="demo-ruleForm"
            label-position="left"
          >
            <el-form-item label="发送节点" :required="true">
              <span slot="label" style="margin-right: 15px">发送节点</span>
              <el-select
                filterable
                v-model="sendContentInfo.s_time"
                style="width: 383px"
                placeholder="请选择发送节点"
              >
                <el-option
                  v-for="(item, index) in sendPointTime"
                  :key="index"
                  :label="index | formatTimeLength"
                  :value="index"
                >
                  <span>{{ index | formatTimeLength}}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发送人" :required="true">
              <span slot="label" style="margin-right: 15px">发送人</span>
              <span v-if="sendContentInfo.user_id" style="margin-right:15px;">{{ sendContentInfo.uname }}</span>
              <el-button type="text" @click="showSender = true" :disabled="!!sendContentInfo.msg_content_id">
                {{sendContentInfo.user_id?'修改':'添加发送人'}}
              </el-button>
            </el-form-item>
            <el-form-item label="发送内容" :required="true">
              <span slot="label" style="margin-right: 15px">发送内容</span>
              <el-radio-group v-model="msg_type" @input="sendContentInfo.content=null">
                <el-radio :label="1">文字</el-radio>
                <el-radio :label="2">图片</el-radio>
              </el-radio-group>
              <div v-if="msg_type==1">
                <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="sendContentInfo.content"
                  maxlength="30"
                  show-word-limit
                >
                </el-input>
              </div>
              <ImgUploadBtn v-else :imgsrc="sendContentInfo.content" @addImg="addpicturestatus = true"></ImgUploadBtn>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeContent">取消</el-button>
          <el-button type="primary" @click="sendContentInfo.msg_content_id?editContentInfo():saveContentInfo()">保存</el-button>
        </div>
      </el-dialog>

      <!-- 选择发送人 -->
      <el-dialog
        v-if="showSender"
        title="选择发送人"
        :visible.sync="showSender"
        :close-on-click-modal="false"
        width="850px"
      >
        <all-data
          class="all-data"
          :isChatRecord="true"
          :isSingleSelect="true"
          @close="showSender = false,showContent=true"
          @next="getSender"
        ></all-data>
      </el-dialog>
  
      <!-- 本地导入 -->
      <local-import
        :tempplateListfromHistory="tempplateList"
        v-if="status"
        :msg_id="msg_id"
        :dialogstatus.sync="status"
        @fileChangeComplete="fileChangeComplete"
      ></local-import>

      <!-- 本地上传弹窗 -->
      <upload
        v-if="uploadStart"
        @close="localUpload"
        :typeselection="1"
        :title="'上传图片(多选)'"
        :uploadType="2"
        :list="group_id1"
      ></upload>
      <!-- 图片选择弹窗 localUpload 选中图片的回调 list  获取选中左侧列表的id group_id-->
      <addpicture
        :size="10"
        v-if="addpicturestatus"
        :dialogstatus="addpicturestatus"
        @locationUpload="localUpload"
        :type="1"
        ref="addpicture"
        @group_id="groupIdChange"
        @list="uploadComplete"
        @close="uploadConfirm"
      ></addpicture>
    </div>
  </div>
</template>
<script>
import loading from '@/components/LoadGif'
import localImport from '../components/localImport'
import addVideo from './addVideo'
// import onputopenclassExcel from '../components/onputopenclassExcel'
import allData from '@view/liveRobot/components/allData'
import ImgUploadBtn from '@/components/ImgUploadBtn'
//本地上传弹窗
import upload from '@cm/base/UploadtextFile'
//图片组件
import addpicture from '@cm/base/addpicture'
export default {
  name: 'messageTemplate',
  // 监听表格滚动
  directives: {
    loadmore: {
      bind(el, binding) {
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
            const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight;
            if (scrollDistance <= 1.5) {
              binding.value()//执行在使用时绑定的函数，在这里即loadMorePerson方法
            }
        });
      },
    },
  },
  components: {
    loading,
    localImport,
    //  onputopenclassExcel
    addVideo,
    allData,
    ImgUploadBtn,
    upload,
    addpicture,
  },
  data() {
    return {
      // 全选组件的状态
      isIndeterminate: false,

      // 单选框的数据
      overdue: 2,
      pickerOptions: {
        disabledDate(time) {
          let now = Date.now()
          let seven = 7 * 24 * 60 * 60 * 1000
          let sevenDays = now - seven
          return time.getTime() > now || time.getTime() < sevenDays
        },
      },

      onputopenclassExcel: false,

      loadding: false,

      status: false,

      msgCount: 0,

      showAddTemplate: false,

      options: [
        {
          value: 0,
          label: '全部角色',
        },
        {
          value: 1,
          label: '真实学员',
        },
        {
          value: 2,
          label: '自定义机器人',
        },
        {
          value: 3,
          label: '系统机器人',
        },
      ],

      options2: [
        {
          value: 1,
          label: '发送时间',
        },
        {
          value: 2,
          label: '添加时间',
        },
      ],

      isNoData: false,

      datepicker: [],

      post2: {
        msg_id: this.msg_id,
        page:1,
      },

      post: {
        user_role: 0,
        type: 1,
        start_time: '',
        end_time: '',
      },

      tempplateContentListCount: 0,

      timeSearch: '',

      start_time: '',

      end_time: '',

      // 全选 数据库所有数据 1是 0否
      is_all:0,

      formData: {
        page: '',
      },

      // 直播记录
      allHistoryList: [],

      // 公开课列表
      allOpenList: [],

      ruleForm: {
        msg_id: this.msg_id,
        open_class_id: '',
        live_history_id: '',
        type: 1,
        start_time: '',
        end_time: '',
        date: '',
        time: '',
        import_type: 2,
      },

      changetimeradio: 1,

      // 调整的时间
      changetime: [0, 0, 0],

      // 批量调整时间
      changeMoreTime: false,

      // 编辑内容
      iseditContent: false,

      tempplateContentList: [],
      count:0,

      tempplateList: [],

      templateName: '',

      templateMessage: '',

      importIemplate: '',

      // 视频信息
      videoInfo:{},
      // 视频时长
      sendPointTime:0,
      
      // 发送内容类型
      msg_type:1,
      // 文本内容
      sendContentInfo:{},

      selectList: [],

      editMessageItem: {},

      selectTemplate: null,

      showImportTemplate: false,

      showAddMessage: false,

      showContent: false,

      showSender: false,
      // 上传图片
      uploadStart: false,
      addpicturestatus: false,
      group_id1:'',
      // 下拉加载
      noLoad:false,

      // 1导入中 2已完成
      dataStatus: 2,
    }
  },

  props: {
    msg_id: {
      type: String,
      default: '',
    },
  },
  watch: {},

  created() {
    this.post2.msg_id = this.msg_id
    this.ruleForm.msg_id = this.msg_id
  },

  filters: {
    getrole(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '真实学员'
          break
        case 2:
          text = '自定义机器人'
          break
        case 3:
          text = '系统机器人'
          break
      }
      return text
    },
    getMesStatus(val){
      let text = ''
      switch (Number(val)) {
        case 2:
          text = '显示'
          break
        case 1:
          text = '隐藏'
          break
      }
      return text
    },
  },
  methods: {
    toSearch() {
      this.post2 = _.assign({}, this.post)
    },

    //搜索时间类型
    typeChange() {
      this.post = _.assign({}, this.post, {
        start_time: '',
        end_time: '',
      })
      this.datepicker = []
      this.timeSearch = ''
    },
    addContent(){
      if(!this.videoInfo.video_url) return
      this.showContent = true
    },

    datepickerChange(val) {
      if (val) {
        this.post.start_time = val[0] / 1000
        this.post.end_time = val[1] / 1000
        // 进行重新赋值操作 结束时间 + 86399
        // this.datepicker = [val[0], val[1] + 86399000]
      } else {
        this.typeChange()
      }
      // this.$refs.childDialogBox.reset()
    },

    // element有个bug
    // https://www.tapd.cn/23590331/bugtrace/bugs/view?bug_id=1123590331001005223
    changeTimePickerBlur(val) {
      this.timeSearch = val
      this.$nextTick(() => {
        const node = document.querySelector(
          '.el-time-panel__btn.confirm[disabled="disabled"]'
        )
        node && node.removeAttribute('disabled')
      })
    },

    changeTimePicker(val) {
      if (val) {
        this.post.start_time = val[0] / 1000
        this.post.end_time = val[1] / 1000
      } else {
        this.typeChange()
      }
      // this.$refs.childDialogBox.reset()
    },

    // 公开课导出到excel
    // onputOpenclassExcel() {
    //   this.onputopenclassExcel = true
    // },

    reset() {
      this.msgCount = 0
      this.ruleForm = _.assign({}, this.ruleForm, {
        live_history_id: '',
        date: '',
        time: '',
        start_time: '',
        end_time: '',
      })
    },

    fileChangeComplete() {
      this.getTempplateList()
      this.$refs.childDialogBox.reset()
    },

    timePickerChange2() {
      const val = this.ruleForm.time
      if (val) {
        const date = this.ruleForm.date / 1000
        let start_time = val[0].split(':').map(item => Number(item))
        let end_time = val[1].split(':').map(item => Number(item))

        let startCount =
          start_time[0] * 60 * 60 + start_time[1] * 60 + start_time[2]

        let endCount = end_time[0] * 60 * 60 + end_time[1] * 60 + end_time[2]
        this.ruleForm.start_time = startCount + date
        this.ruleForm.end_time = endCount + date
      } else {
        this.ruleForm.start_time = ''
        this.ruleForm.end_time = ''
        this.msgCount = 0
      }
      this.getMsgCount()
    },
    loadTable() {
      if (this.noLoad) return;
      clearTimeout(this.loadT)
      this.loadT = setTimeout(()=>{
        this.$refs.childDialogBox.currentChange(this.page+1)
      },500)
    },
    // 获取公开课信息数量
    async getMsgCount() {
      this.cancelOption()
      if (
        (this.ruleForm.type == 2 &&
          this.ruleForm.start_time &&
          this.ruleForm.date) ||
        this.ruleForm.type == 1
      ) {
        this.getMsgCount2()
      } else {
        this.msgCount = 0
      }
    },

    // 获取公开课信息数量
    getMsgCount2() {
      this.loadding = true
      this.xhr = this.$http({
        url: '/chatMessage/getMsgCount',
        data: _.assign({}, this.ruleForm, {
          open_class_id: this.ruleForm.open_class_id,
        }),
      })

      this.xhr.then(({ data }) => {
        this.msgCount = data
        this.loadding = false
      })
    },

    // 取消请求
    cancelOption() {
      if (this.xhr) {
        this.xhr.abort()
      }
    },

    // 取消创建模板
    cancelshowAddTemplate() {
      this.showAddTemplate = false
      this.templateName = ''
      this.msg_id = ''
    },

    // 新增或编辑模板
    async submitShowAddTemplate() {
      if (!this.templateName.trim()) {
        this.$root.prompt({
          msg: '请输入模板名称',
        })
        return
      }

      await this.$http({
        url: this.msg_id
          ? '/chatMessage/editChatMsg'
          : '/chatMessage/addChatMsg',
        data: {
          name: this.templateName,
          msg_id: this.msg_id ? this.msg_id : undefined,
        },
      })
      this.$root.prompt({
        msg: '操作成功!',
        type: 'success',
      })

      this.getTempplateList()
      this.cancelshowAddTemplate()
    },

    loadLocation() {
      if (this.tempplateContentList.length == 0) {
        this.$root.prompt('没有可导出的内容')
        return
      }
      this.$store.dispatch(
        'open',
        `${location.origin}/ChatMessage/exportExecel?msg_id=${this.msg_id}`
      )
    },

    onputExcel() {
      this.$nextTick(() => {
        this.status = true
      })
    },

    complete({ data, status,page,count,video_name,video_url,duration=0 }) {
      if (data && Array.isArray(data)) {
        const filter = this.$options.filters['formatTimeLength']
        data.forEach(item => {
          item.s_time_copy = item.s_time
          item.s_time = filter(item.s_time)
        })
        // 下拉加载数据
        if(count==0){
          this.tempplateContentList = []
        } else {
          this.tempplateContentList = data
          // 去重
          // let list = _.uniqBy(data.concat(this.tempplateContentList),'msg_content_id')
          // this.tempplateContentList = _.sortBy(list,v=>v.s_time)
        }
      }
      this.dataStatus = status
      this.page=page
      this.count = count
      this.$refs.addVideo.setVideoInfo({
        video_name,
        video_url,
      })
      this.videoInfo = {
        video_name,
        video_url,
      }
      this.sendPointTime=Number(duration)+1
      if (this.tempplateContentList.length == count) {
        this.noLoad = true;
      } else {
        this.noLoad = false;
      }
    },
    // 编辑模板
    editTemplateContent(val){
      let data = _.cloneDeep(val)
      this.sendContentInfo = Object.assign(data,{
        s_time:Number(data.s_time_copy),
      })
      this.msg_type = Number(data.msg_type)
      this.showContent = true
    },

    // 显示或隐藏模板
    SOrHTemplateContent(val,is_shield,is_all=0){
      let str = ''
      let str2 = ''
      switch (is_shield||Number(val.is_shield)) {
        case 2:
          str = `是否确定隐藏${is_all==1?'全部':'已选'}的消息？`
          str2 = '隐藏消息'
          break;
        case 1:
          str = `是否确定显示${is_all==1?'全部':'已选'}的消息？`
          str2 = '显示消息'
          break;
      }
      this.is_all = is_all
      let ids = val&&val.msg_content_id ? val.msg_content_id
        : this.is_all==1?_.difference(this.tempplateContentList.map(item => item.msg_content_id),this.selectList.map(item => item.msg_content_id))
        : this.selectList.map(item => item.msg_content_id)
      this.$confirm(str, str2, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          let show = is_shield||val.is_shield
          this.SOrHContent(ids,show==1?2:1)
        })
        .catch(() => {})
    },

    // 删除模板
    delTemplateContent(val,is_all=0) {
      let ids = val&&val.msg_content_id
        ? val.msg_content_id
        : this.selectList.map(item => item.msg_content_id)
      let str = ''
      switch (Number(is_all)) {
        case 0:
          str = '是否确定删除已选的消息？'
          break;
        case 1:
          str = '是否确定删除全部的消息？'
          break;
      }
      this.is_all = is_all
      this.$confirm(str, '删除消息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.delContent(ids)
        })
        .catch(() => {})
    },

    // 显示或隐藏 
    async SOrHContent(ids,is_shield){
      try {
        await this.$http({
          url: '/chatMessage/setShield',
          data: {
            msg_content_ids: ids,
            is_shield:is_shield,
            msg_id:this.msg_id,
            is_all:this.is_all,
          },
        })
        this.$refs.childDialogBox.reset()
        this.getTempplateList()
        this.$root.prompt({
          msg: '操作成功',
          type: 'success',
        })
      } catch (e) {
        console.log(e)
      }
    },

    async delContent(ids) {
      try {
        await this.$http({
          url: '/chatMessage/delContent',
          data: {
            msg_id:this.msg_id,
            msg_content_ids: ids,
            is_all:this.is_all,
          },
        })
        this.$refs.childDialogBox.reset()
        this.getTempplateList()
        this.$root.prompt({
          msg: '操作成功',
          type: 'success',
        })
      } catch (e) {
        console.log(e)
      }
    },

    opencourseFocus() {
      this.getopenClassList()
    },

    changeOpencourse(val) {
      this.ruleForm = _.assign({}, this.ruleForm, {
        open_class_id: val,
        live_history_id: '',
        type: 1,
        start_time: '',
        end_time: '',
        date: '',
        time: '',
      })
      // 切换公开课时，恢复数据
      this.msgCount = 0
      this.getallHistoryList()
    },

    getlabel(val) {
      let text = ''
      const filter = this.$options.filters['formatTimeStamp']
      const filter2 = this.$options.filters['formatTimeLength']
      text =
        filter(val.start_time, 'yyyy-MM-dd hh:mm') +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        '时长：' +
        filter2(val.push_duration)
      return text
    },

    // 获取公开课数据
    async getopenClassList() {
      try {
        const { data } = await this.$http({
          url: '/openClass/allOpenList',
          data: { type: 1 },
        })
        this.allOpenList = data
      } catch (e) {
        console.log(e)
      }
    },

    // 获取公开课直播记录数据
    async getallHistoryList() {
      try {
        const { data } = await this.$http({
          url: '/openClass/allHistoryList',
          data: {
            open_class_id: this.ruleForm.open_class_id,
          },
        })
        this.allHistoryList = data
      } catch (e) {
        console.log(e)
      }
    },

    // 取消批量调整时间
    cancelToChangeMoreTime() {
      this.changetimeradio = 1
      this.changetime = [0, 0, 0]
      this.changeMoreTime = false
    },

    // 批量编辑调整发送时间 selectList
    async toChangeMoreTime() {
      const msg_content_ids = this.is_all==1?_.difference(this.tempplateContentList.map(item => item.msg_content_id),this.selectList.map(item => item.msg_content_id))
        :this.selectList.map(item => item.msg_content_id)

      const move_time =
        this.changetime[0] * 3600 + this.changetime[1] * 60 + this.changetime[2]

      try {
        await this.$http({
          url: '/chatMessage/setPluralTime',
          data: {
            msg_id: this.msg_id,
            msg_content_ids: msg_content_ids,
            move_sort: this.changetimeradio,
            move_time: move_time,
            is_all:this.is_all,
          },
        })
        this.$root.prompt({
          msg: '操作成功',
          type: 'success',
        })
        this.cancelToChangeMoreTime()
        this.$refs.childDialogBox.reset()
      } catch (e) {
        console.log(e)
      }
    },

    showchagetimedialog(is_all = 0) {
      this.is_all = is_all
      this.changeMoreTime = true
    },

    // 编辑时间
    async timePickerChange(val, row) {
      const time = row.s_time.split(':')
      let s_time =
        Number(time[0]) * 3600 + Number(time[1]) * 60 + Number(time[2])
      try {
        await this.$http({
          url: '/chatMessage/setTime',
          data: {
            msg_content_id: row.msg_content_id,
            s_time,
          },
        })
        this.$refs.childDialogBox.reset()
        this.$root.prompt({
          msg: '操作成功',
          type: 'success',
        })
      } catch (e) {
        console.log(e)
      }
    },

    // 模板列表
    getTempplateList() {
      // this.tempplateListLoading = true
      // this.$root.$http({
      //   url: '/chatMessage/templateList',
      //   data: {},
      //   callback: ({ data }) => {
      //     this.tempplateList = data
      //     if (!this.selectTemplate && data.length > 0) {
      //       this.selectTemplate = data[0]
      //       this.post = _.assign({}, this.post, {
      //         msg_id: this.selectTemplate.msg_id,
      //         start_time: this.start_time,
      //         end_time: this.end_time,
      //       })
      //     }
      //     this.tempplateListLoading = false
      //   },
      //   error: () => {
      //     this.tempplateListLoading = false
      //   },
      // })
    },

    // 模板弹窗
    async importTemplate() {
      const status1 =
        this.tempplateList.filter(item => item.status == 1).length > 2
      if (status1) {
        this.$root.prompt('模板同时导入数量不能超过两个')
      } else {
        this.showImportTemplate = !this.showImportTemplate
        this.importIemplate = ''
        this.templateName = ''
        this.ruleForm = {
          open_class_id: '',
          live_history_id: '',
          type: 1,
          msg_id: '',
          import_type: 2,
        }
        await this.getopenClassList()
      }
    },

    // 模板消息内容
    addMessage() {
      this.showAddMessage = !this.showAddMessage
    },

    // 选中模板
    selectTem(value) {
      this.selectTemplate = value
      this.post.msg_id = value.msg_id
      this.getTempplateList()
      this.$refs.childDialogBox.reset()
    },

    // 保存模板
    importTemplateSave() {
      if (!this.ruleForm.open_class_id) {
        this.$root.prompt('请选择公开课')
      } else if (this.ruleForm.type == 1 && !this.ruleForm.live_history_id) {
        this.$root.prompt('请选择直播记录')
      } else if (
        this.ruleForm.type == 2 &&
        (!this.ruleForm.date ||
          !this.ruleForm.start_time ||
          !this.ruleForm.end_time)
      ) {
        this.$root.prompt('请选择时间')
      } else {
        this.isNoData = true
        this.$root.$http({
          url: '/chatMessage/saveHistory',
          data: _.assign({}, this.ruleForm, { msg_id: this.msg_id }),
          callback: () => {
            this.$root.prompt({
              msg: '添加成功',
              type: 'success',
            })
            this.$refs.childDialogBox.reset()
            this.templateName = ''
            this.importTemplate()
            this.getTempplateList()
            this.isNoData = false
          },
        })
      }
    },

    // 复制
    copyTemplate({ msg_id, status }) {
      if (status == 1) {
        this.$root.prompt('模板导入中，无法复制')
      } else {
        this.$root.$http({
          url: '/chatMessage/copyChatMsg',
          data: {
            msg_id,
          },
          callback: () => {
            this.$root.prompt({
              msg: '复制成功',
              type: 'success',
            })
            this.getTempplateList()
            document.body.click()
          },
        })
      }
    },

    // 编辑
    async editTemplate({ msg_id }) {
      document.body.click()
      this.msg_id = msg_id
      await this.editChatMsg(msg_id)
      this.showAddTemplate = true
    },

    async editChatMsg(val) {
      try {
        const { data } = await this.$http({
          url: `/chatMessage/editChatMsg?msg_id=${val}`,
        })
        this.templateName = data.name
      } catch (e) {
        console.log(e)
      }
    },

    // 删除
    async removeTemplate({ msg_id }) {
      await this.$confirm(
        '删除模板会将模板内的消息同时删除，不可恢复！',
        '删除消息模板',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
      this.$root.$http({
        url: '/chatMessage/delChatMsg',
        data: {
          msg_id,
        },
        callback: () => {
          this.$root.prompt({
            msg: '删除成功',
            type: 'success',
          })
          if (this.selectTemplate.msg_id === msg_id) {
            this.selectTemplate = null
          }

          this.getTempplateList()
          document.body.click()
        },
      })
    },

    // 消息内容变化
    templateMessageChange(value) {
      this.templateMessage = value
        .split('\n')
        .map(item => item.slice(0, 255))
        .join('\n')
    },

    // 添加消息内容
    templateMessageSave() {
      if (!this.templateMessage.trim()) {
        this.$root.prompt('请输入发言内容')
      } else {
        const result = this.templateMessage.split('\n').filter(item => !!item)
        this.$root.$http({
          url: '/liveChat/addLiveChatContent',
          data: {
            contents: result,
            msg_id: this.selectTemplate.msg_id,
          },
          callback: () => {
            this.$root.prompt({
              msg: '添加成功',
              type: 'success',
            })
            this.templateMessage = ''
            this.addMessage()
            // 模板列表
            this.getTempplateList()
            // 模板内容列表
            this.$refs.childDialogBox.reset()
          },
        })
      }
    },

    // 全选
    checkALL(val) {
      this.is_all = 0
      if (val == 1) {
        this.$refs.multipleTable.toggleAllSelection()
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    // checkIsALL(val){
    //   if (
    //     this.tempplateContentList.length != this.selectList.length && 
    //     val == 1
    //   ) {
    //     this.$refs.multipleTable.toggleAllSelection()
    //   } else {
    //     this.$refs.multipleTable.clearSelection()
    //   }
    //   this.is_all = val
    // },
    selectionChange(value) {
      this.is_all = 0
      this.selectList = value
      this.isIndeterminate =
        this.selectList.length > 0 &&
        this.tempplateContentList.length != this.selectList.length
      if (
        this.selectList.length > 0 &&
        this.tempplateContentList.length == this.selectList.length
      ) {
        this.overdue = 1
      } else {
        this.overdue = 2
      }
      this.$forceUpdate()
    },
    // 视频信息
    setVideoInfo(val){
      this.videoInfo = val
      this.sendPointTime = Number(val.classHour)+1
    },
    // 获取发送人
    getSender(val){
      this.sendContentInfo = Object.assign(this.sendContentInfo,{
        user_id:val.robot_id,
        uname:val.name,
      })
      this.showSender=false
      this.showContent=true
    },
    closeContent(){
      this.showContent = false
      this.msg_type=1
      this.sendContentInfo={}
    },
    // 编辑消息
    editContentInfo(){
      if(!this.sendContentInfo.s_time&&this.sendContentInfo.s_time!=0){
        this.$root.prompt({
          msg: '请选择发送节点！',
          type: 'error',
        })
      } else if(!this.sendContentInfo.user_id){
        this.$root.prompt({
          msg: '请选择发送人！',
          type: 'error',
        })
      } else if(!this.sendContentInfo.content){
        this.$root.prompt({
          msg: '请填写发送内容！',
          type: 'error',
        })
      } else {
        this.$http({
          url: '/chatMessage/setContent',
          data: Object.assign(this.sendContentInfo,{
            msg_id:this.msg_id,
            msg_type:this.msg_type,
          }),
          callback: () => {
            this.$root.prompt({
              msg: '编辑成功',
              type: 'success',
            })
            this.sendContentInfo = {}
            this.showContent = false
            this.$refs.childDialogBox.reset()
          },
        })
      }
    },
    // 添加消息
    saveContentInfo(){
      if(!this.sendContentInfo.s_time&&this.sendContentInfo.s_time!=0){
        this.$root.prompt({
          msg: '请选择发送节点！',
          type: 'error',
        })
      } else if(!this.sendContentInfo.user_id){
        this.$root.prompt({
          msg: '请选择发送人！',
          type: 'error',
        })
      } else if(!this.sendContentInfo.content){
        this.$root.prompt({
          msg: '请填写发送内容！',
          type: 'error',
        })
      } else {
        this.$http({
          url: '/chatMessage/addMsgContent',
          data: Object.assign(this.sendContentInfo,{
            msg_id:this.msg_id,
            msg_type:this.msg_type,
          }),
          callback: () => {
            this.$root.prompt({
              msg: '添加成功',
              type: 'success',
            })
            this.sendContentInfo = {}
            this.showContent = false
            this.$refs.childDialogBox.reset()
          },
        })
      }
    },
    //本地上传弹窗控制
    localUpload() {
      this.uploadStart = !this.uploadStart
    },
    //获取图片上传中的分组id
    groupIdChange(val) {
      this.group_id1 = val
    },
    uploadComplete(val) {
      this.sendContentInfo.content = val.url
    },
    uploadConfirm() {
      this.addpicturestatus = !this.addpicturestatus
    },
  },
}
</script>
<style lang="scss">
.liveSettings-template-setting {
  min-width: auto !important;
}
.chatRecordpopperClass {
  left: 645px !important;
}
</style>
<style lang="scss" scoped>
.cp {
  cursor:pointer;
}
.tips {
  font-size: 14px;
  color: #333333;
}
.chatTable {
  ::v-deep .el-table__body-wrapper {
    max-height: calc(100vh - 410px);
    overflow-y: auto;
  }
  ::v-deep .el-table__cell {
    padding:0px 0px;
  }
  ::v-deep .el-table__header-wrapper  .el-checkbox{
    display:none
  }
  ::v-deep .el-divider {
    background-color: #1b9d97 !important;
  }
}
.all-data {
  ::v-deep .el-table__body-wrapper {
    max-height: calc(100vh - 480px);
    overflow-y: auto;
  }
}
::v-deep .change-more-time {
  .el-input-number {
    width: 70px;
    margin: 20px 0;
  }
  .el-input-number__increase,
  .el-input-number__decrease {
    width: 20px;
    background-color: #fff;
    border: 0 !important;
  }
  .is-controls-right .el-input__inner {
    padding: 0px 20px 0 5px;
  }
}
img {
  height: 100%;
  width: 100%;
}

::v-deep .gutter {
  background-color: rgb(245, 245, 245);
}
#messageContainer{
  display: flex;
  .messageTemplate {
    min-width: 535px;
    background-color: #fff;
    margin: -20px 0 0 0px;
    // padding-right:20px;
    .title {
      font-size: 13px;
      color: #333333;
      line-height: 20px;
    }
    .add-template {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0 10px;
      .btn {
        width: 270px;
        height: 46px;
        font-size: 15px;
        font-weight: bold;
        color: #ffffff;
        line-height: 46px;
        text-align: center;
        background: #0aa29b;
        cursor: pointer;
      }
      .timepicker {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .tem-name {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
        margin-bottom: 8px;
        .btn_contain {
          display: flex;
          align-items: center;
          .driver {
            margin: 0 10px;
            flex-shrink: 0;
            color: #0aa29b;
            height: 100%;
          }
        }
      }
    }
  }
}
.template-copntain {
  display: flex;
  .template-detail {
    overflow: hidden;
    flex: 1;
    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: rgba(0, 0, 0, 0) !important;
    }

    .people-contain {
      display: flex;
      align-items: center;
      .cover-img {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        margin-right: 10px;
        background: #c5c5c5;
      }
      .name {
        flex: 1;
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    ::v-deep .select-send-time {
      display: flex;
      align-items: center;
      .el-date-editor {
        width: 118px;
        margin-left:-20px;
      }
      .el-input__inner {
        border: 0;
        padding-right: 0px;
      }
      .cp {
        cursor: pointer;
      }
    }
    ::-webkit-scrollbar {
      width: 2px;
      height: 8px;
      background-color: #fff;
    }

    /*定义滚动条轨道 内阴影+圆角*/
    ::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
      background-color: #fff;
    }

    /*定义滑块 内阴影+圆角*/
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #ccc;
    }
    .top {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .add-msg {
        width: 150px;
        line-height: 46px;
        background: #0aa29b;
        border-radius: 4px;
        font-size: 15px;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .footer {
    margin-top: 20px;
    .total {
      font-size: 14px;
      color: #333333;
    }
  }
}
.template-setting {
  .item {
    font-size: 14px;
    color: #2f2f2f;
    padding: 7px 0;
    text-align: center;
    cursor: pointer;
    &:hover {
      background: #eee;
    }
  }
}
::v-deep .caozuo {
  font-size: 12px;
  color: #0aa29b;
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .sp_drive {
    margin: 0 5px;
  }
  .el-button {
    font-size: 12px;
    color: #0aa29b;
    &.is-disabled {
      color: #c0c4cc;
    }
  }
}

</style>
<style lang="scss">
.chatRecord_popper {
  width: 122px !important;
  min-width: auto;
  .popover_item {
    .item {
      text-align: center;
      font-size: 14px;
      color: #333333;
      line-height: 40px;
      cursor: pointer;
      &:hover {
        color: #0aa29b;
      }
    }
    .noClick {
      cursor:not-allowed;
    }
  }
}
.chatRecord_popper2 {
  width: 62px !important;
}
.chatRecord_popper3 {
  width: 120px !important;
}
</style>
