<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-10-26 10:46:30
 * @LastEditTime: 2022-11-07 11:16:56
 * @LastEditors: cyy
 * @Description:从分组选择
 * @FilePath: \teacherdingding.com\src\views\LiveBroadcastManagement\messageTemplate\details\robotSelect\groups.vue
-->
<template>
  <div class="groups">
    <el-dialog
      title="从机器人分组选择"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <!-- 顶部搜索 -->
        <div class="header mb10">
          <div>
            <el-input
              clearable
              @keyup.enter.native="toSearch"
              v-model="search"
              size="medium"
              style="width:188px"
              placeholder="输入分组搜索"
            ></el-input>
            <el-button
              type="primary"
              class="ml20"
              style="width:74px"
              @click="toSearch"
              size="medium"
            >
              搜索
            </el-button>
          </div>
        </div>
        <!-- 表格 -->
        <pagination2
          ref="child"
          :option="formData"
          url="/pcUser/getGroupList"
          @complete="complete"
          class="pagination2"
        >
          <template>
            <el-table
              height="400"
              ref="dialogTable"
              @row-click="rowClick"
              @sort-change="sortChange1"
              @selection-change="handleSelectionChange"
              :data="completeData"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
            >
              <el-table-column type="selection" width="45px"></el-table-column>
              <el-table-column prop="gname" label="分组名称" width="180px" />
              <el-table-column
                label="机器人数量"
                prop="number"
              ></el-table-column>
              <el-table-column label="创建人" prop="cname">
                <template slot-scope="{ row }">
                  {{ row.cname || '-' }}
                </template>
              </el-table-column>
              <el-table-column label="创建时间" sortable="custom">
                <template slot-scope="{ row }">
                  {{ row.create_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
                </template>
              </el-table-column>
            </el-table>
          </template>
        </pagination2>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" @click="submit" size="medium">
          保 存
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isIndeterminate: false,

      completeData: [],

      overdue: 2,

      selectList: [],

      formData: {
        gids: [],
        search: '',
        type: '1',
        user_type: '2',
        sortType: '',
        orderType: 2,
      },

      search: '',
    }
  },

  created() {
    this.formData.gids = this.gids
  },

  props: {
    // 已经选择的id
    gids: {
      type: Array,
      default: () => [],
    },

    // 限制选择的个数
    limit: {
      type: Number,
      default: 0,
    },

    // 已经选择的个数
    allowance: {
      type: Number,
      default: 0,
    },

    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    submit() {
      if (this.allowance + this.selectList.length > this.limit) {
        this.$root.prompt('总数量超过3个')
        return
      }
      this.$emit('robotselectComplete', this.selectList)
      this.cancel()
    },

    rowClick(row) {
      this.$refs.dialogTable.toggleRowSelection(row)
    },

    sortChange1(val) {
      this.formData.sortType = !val.order
        ? ''
        : val.order === 'ascending'
        ? '2'
        : '1'
    },

    toSearch() {
      this.formData = _.assign({}, this.formData, { search: this.search })
    },

    // 全选的处理
    complete({ data }) {
      this.completeData = data
    },

    handleSelectionChange(val) {
      this.selectList = val
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.groups {
  img {
    width: 100%;
    width: 100%;
  }
  .el-divider {
    background: rgba(27, 157, 151, 1);
  }

  ::v-deep .el-dialog__body {
    padding: 0 20px 20px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .tips {
    flex-shrink: 0;
    font-size: 14px;
    color: #333333;
    margin-right: 20px;
  }
  .pagination2 {
    @extend %scroll;
    flex: 1;
    overflow: auto;
  }
}
</style>
