<template>
  <div class="ImgUploadContainer">
    <img
      class="avatar-img"
      :key="keys"
      v-if="imgsrc" 
      :src="imgsrc" 
    >
    <div class="avatar" :class="`${imgsrc ? 'avatar-white avatar-show' : ''}`" @click="$emit('addImg')">
      <i class="el-icon-plus avatar-uploader-icon" :class="`${imgsrc ? 'avatar-uploader-icon-white' : ''}`"></i>
      <p>
        上传图片
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImgUploadBtn',
  props: {
    imgsrc: String,
  },
  data() {
    return {
      hover:false,
      keys:1,
    }
  },
}
</script>

<style lang="scss" scoped>
.ImgUploadContainer {
  position: relative;
  width: 96px;
  height: 96px;

  .avatar-img {
    width: 96px;
    height: 96px;
    cursor: pointer;
    border-radius: 6px;
  }
  .avatar {
    width: 96px;
    height: 96px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    visibility: visible;
    font-size:12px;
  }

  .avatar-uploader-icon {
    font-size: 18px;
    color: #8c939d;
    text-align: center;
  }

  .avatar-white {
    color: #fff;
  }
  .avatar-show {
    visibility: hidden;
  }
  .avatar-uploader-icon-white {
    color: #fff;
  }
}
.ImgUploadContainer:hover .avatar{
  visibility: visible;
}
</style>