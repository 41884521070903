<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-10-24 16:29:54
 * @LastEditTime: 2022-11-03 15:13:06
 * @LastEditors: cyy
 * @Description: 话术本详情
 * @FilePath: \teacherdingding.com\src\views\LiveBroadcastManagement\messageTemplate\messageDetail.vue
-->
<template>
  <div class="messageDetail">
    <div class="header" v-if="headerList.type != 3">
      <el-descriptions :column="2">
        <el-descriptions-item label="话术本">
          <span :title="headerList.name">{{ headerList.name }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="类型">
          {{ headerList.type | gettype }}
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">
          {{ headerList.c_time | formatTimeStamp }}
        </el-descriptions-item>
        <el-descriptions-item label="创建者">
          {{ headerList.uname }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="header" v-else>
      <el-descriptions :column="4">
        <el-descriptions-item label="话术本">
          <span :title="headerList.name">{{ headerList.name }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="来源">
          {{ headerList.msg_type | getmsgtype }}
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">
          {{ headerList.c_time | formatTimeStamp }}
        </el-descriptions-item>
        <el-descriptions-item label="创建者">
          {{ headerList.uname }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="main_list">
      <!-- 自动发言 -->
      <automatic-speech
        :msg_id="msg_id"
        v-if="headerList.type == 1"
      ></automatic-speech>
      <!-- 模拟对话 -->
      <simulate-conversation
        :msg_id="msg_id"
        v-if="headerList.type == 2"
      ></simulate-conversation>
      <!-- 聊天记录 -->
      <chat-record ref="chatRecord" :msg_id="msg_id" v-if="headerList.type == 3"></chat-record>
    </div>
  </div>
</template>

<script>
import chatRecord from './details/chatRecord'
import simulateConversation from './details/simulateConversation'
import automaticSpeech from './details/automaticSpeech'
import bg from '@/mixin/background'

export default {
  mixins: [bg],

  components: {
    automaticSpeech,
    simulateConversation,
    chatRecord,
  },

  data() {
    return {
      headerList: {},
    }
  },

  filters: {
    gettype(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '自动发言 '
          break
        case 2:
          text = '模拟对话'
          break
        case 3:
          text = '聊天记录'
          break
      }
      return text
    },
    getmsgtype(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '直播生成 '
          break
        case 2:
          text = '手动增加'
          break
      }
      return text
    },
  },

  computed: {
    msg_id() {
      return this.$route.query.msg_id
    },
  },

  created() {
    this.contentHead()
  },

  methods: {
    async contentHead() {
      const { data } = await this.$http({
        url: '/chatMessage/contentHead',
        data: {
          msg_id: this.msg_id,
        },
      })

      this.headerList = data
    },
  },
}
</script>

<style lang="scss" scoped>
.messageDetail {
  padding: 20px;
  ::v-deep .el-descriptions-item__label {
    flex-shrink: 0;
  }
  ::v-deep .el-descriptions-item__content {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .header {
    padding: 20px 20px 8px;
    background-color: #fff;
  }
  .main_list {
    margin-top: 20px;
    background-color: #fff;
    padding: 20px;
  }
}
</style>
