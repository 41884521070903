<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-10-24 16:49:38
 * @LastEditTime: 2022-11-04 17:12:27
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @Description: 自动发言
 * @FilePath: \teacherdingding.com\src\views\LiveBroadcastManagement\messageTemplate\details\automaticSpeech.vue
-->
<template>
  <div class="automaticSpeech">
    <el-button type="primary" @click="addMessage" size="medium">
      添加评论
    </el-button>

    <!-- 表格 -->
    <pagination2
      ref="child"
      :option="post"
      url="/chatMessage/chatMsgDetails"
      @complete="complete"
      class="pagination2"
    >
      <template>
        <el-table
          ref="multipleTable"
          :data="completeData"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="45px"></el-table-column>
          <el-table-column label="评论内容">
            <template slot-scope="{ row }">
              <div
                class="flex-center"
                v-if="
                  changeVal.live_chat_list_content_id ===
                    row.live_chat_list_content_id
                "
              >
                <el-input
                  type="textarea"
                  rows="3"
                  v-model="row.content"
                  maxlength="200"
                  autosize
                  autofocus
                  show-word-limit
                ></el-input>
                <el-button type="text" class="ml10" @click="saveMessage(row)">
                  保存
                </el-button>
                <el-button
                  style="color: #999999"
                  type="text"
                  class="ml10"
                  @click="changeContent()"
                >
                  取消
                </el-button>
              </div>
              <div v-else class=" robot_contain" @click="changeContent(row)">
                <div class="name  ">{{ row.content }}</div>
                <i class="el-icon-edit"></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="250">
            <template slot-scope="scope">
              <el-button
                :disabled="scope.$index == 0"
                size="mini"
                type="text"
                @click="moveUpDown(scope.row.live_chat_list_content_id, 1)"
              >
                上移
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button
                size="mini"
                type="text"
                :disabled="scope.$index == completeData.length - 1"
                @click="moveUpDown(scope.row.live_chat_list_content_id, 2)"
              >
                下移
              </el-button>
              <!-- <el-divider direction="vertical"></el-divider>
              <el-button
                size="mini"
                type="text"
                @click="editContent(scope.row)"
              >
                编辑
              </el-button> -->
              <el-divider direction="vertical"></el-divider>
              <el-button
                size="mini"
                type="text"
                @click="del(1, [scope.row.live_chat_list_content_id])"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <div class="footer">
          <el-checkbox
            v-model="overdue"
            :true-label="1"
            :false-label="2"
            class="ml10"
            :indeterminate="isIndeterminate"
            @change="checkALL"
          >
            <div class="tips ml10">已选择{{ selectList.length }}条</div>
          </el-checkbox>

          <el-button
            :disabled="selectList.length == 0"
            size="medium"
            style="width:130px"
            @click="
              del(
                2,
                selectList.map(item => item.live_chat_list_content_id)
              )
            "
            class="mr10 ml20"
          >
            批量删除
          </el-button>
        </div>
      </template>
    </pagination2>

    <!--添加评论-->
    <el-dialog
      title="添加评论"
      :close-on-click-modal="false"
      :visible.sync="showAddMessage"
      width="800px"
    >
      <div slot="title" class="flex-center">
        <div class="t1" style="font-size: 16px; color: #333333">
          添加评论
        </div>
      </div>
      <div style="margin: -20px 0 0 0">
        <el-input
          type="textarea"
          rows="18"
          placeholder="请输入发言内容"
          :value="templateMessage"
          @input="templateMessageChange"
        ></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="tips2">
          温馨提示：单个内容字数不可超过200个，添加多个时可回车换行添加
        </div>
        <el-button @click="addMessage">取消</el-button>
        <el-button type="primary" @click="templateMessageSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 编辑的内容
      toeditVal: {},

      // 编辑内容
      iseditContent: false,

      post: {
        msg_id: this.msg,
      },

      templateMessage: '',

      selectList: [],

      completeData: [],

      isIndeterminate: false,

      overdue: 2,

      // 添加消息模板
      showAddMessage: false,
      changeVal: {},
    }
  },

  created() {
    this.post.msg_id = this.msg_id
  },

  props: {
    msg_id: {
      type: String,
      default: '',
    },
  },

  methods: {
    // 上移下移
    async moveUpDown(id, type) {
      await this.$http({
        url: '/chatMessage/contentSort',
        data: {
          live_chat_list_content_id: id,
          sort: type,
        },
        successMsg: true,
      })
      this.$refs.child.reset()
    },

    // 取消编辑内容
    submitEditCancel() {
      this.toeditVal = {}
      this.iseditContent = false
    },

    // 点击编辑内容
    editContent(row) {
      this.toeditVal = _.assign({}, row)
      this.iseditContent = true
    },

    // 消息内容变化
    templateMessageChange(value) {
      this.templateMessage = value
        .split('\n')
        .map(item => item.slice(0, 255))
        .join('\n')
    },

    // 添加消息内容
    templateMessageSave() {
      if (!this.templateMessage.trim()) {
        this.$root.prompt('请输入发言内容')
      } else {
        const result = this.templateMessage.split('\n').filter(item => !!item)
        this.$root.$http({
          url: '/liveChat/addLiveChatContent',
          data: {
            contents: result,
            msg_id: this.msg_id,
          },
          callback: () => {
            this.$root.prompt({
              msg: '添加成功',
              type: 'success',
            })
            this.templateMessage = ''
            this.addMessage()
            // 模板内容列表
            this.$refs.child.reset()
          },
        })
      }
    },

    // 模板消息内容
    addMessage() {
      this.showAddMessage = !this.showAddMessage
    },

    // 删除评论
    async del(type, val) {
      if (type == 2 && this.selectList.length == 0) {
        this.$root.prompt('请先选择评论')
        return
      }
      this.$confirm('确定删除已选评论？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/liveChat/delContent',
            data: { live_chat_list_content_ids: val },
            successMsg: true,
          })
          this.$refs.child.reset()
        })
        .catch(() => {})
    },

    // 全选
    checkALL(val) {
      if (val == 1) {
        this.$refs.multipleTable.toggleAllSelection()
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },

    toSearch() {
      this.formData2 = _.assign({}, this.formData)
    },

    // 列表选择的数据
    handleSelectionChange(val) {
      this.selectList = val
      this.isIndeterminate =
        this.selectList.length > 0 &&
        this.completeData.length != this.selectList.length
      if (
        this.selectList.length > 0 &&
        this.completeData.length == this.selectList.length
      ) {
        this.overdue = 1
      } else {
        this.overdue = 2
      }
      this.$forceUpdate()
    },

    // 获取列表数据
    complete({ data, count }) {
      this.completeData = data
      this.count = count
    },

    // 修改内容
    changeContent(row) {
      if (row) {
        row.old_content = row.content
        this.changeVal = row
      } else {
        this.changeVal.content = this.changeVal.old_content
        this.changeVal = {}
      }
    },

    // 确定编辑的内容
    async saveMessage(row) {
      if (!row.content.trim()) {
        this.$root.prompt('请输入评论内容')
      } else {
        try {
          await this.$http({
            url: '/liveChat/editContent',
            data: {
              live_chat_list_content_id: row.live_chat_list_content_id,
              content: row.content,
            },
          })
          this.changeVal = {}
          this.$refs.child.reset()
        } catch (e) {
          console.log(e)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.automaticSpeech {
  .el-divider {
    background: rgba(27, 157, 151, 1);
  }
  .tips2 {
    color: #ff3535;
    font-size: 14px;
    position: absolute;
    line-height: 18px;
    margin-top: 11px;
  }
  .tips {
    font-size: 14px;
    color: #333333;
    margin-left: 10px;
  }
  .pagination2 {
    margin-top: 20px;
    .robot_contain {
      display: flex;
      align-items: center;

      .tu {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        font-size: 14px;
        color: #333333;
        margin-right: 10px;
      }
    }
    .footer {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }
  }
}
</style>
